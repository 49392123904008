/* eslint-disable */
import axios from 'axios';
import StatusCode from './StatusCode';

const handleResponse = (response) => {
    if (response.status <= StatusCode.NO_CONTENT) {
        return handleSuccess(response);
    } else {
        return handleError(response);
    }
}

const handleSuccess = (response, ...rest) => {
    const data = response.data;
    return {
        success: true,
        status: response.status,
        data,
        ...rest,
    };
};

const handleError = (response, ...rest) => {
    return {
        success: false,
        status: response.status,
        ...rest,
    };
};

const ApiExecutor = async (type, url, data) => {
    var response;
    switch (type) {
        case 'get': {
            response = await axios.get(url).then(response => { return response });
            return handleResponse(response);
        }
        case 'post': {
            response = await axios.post(url, data).then(response => { return response });
            return handleResponse(response);
        }
        // default:
        // return state;
    }
}

export default ApiExecutor;
