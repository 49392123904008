import * as React from 'react';
import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel';
import { useCookies } from 'react-cookie';

export const ManageCookiesContext = React.createContext({});

export const initialCookiesData = {
  strictly_necessary_cookie: true,
  statistics_cookie: false,
  preferences_cookie: false,
  marketing_cookie: false
};

const cookieKey = 'vendor';

export default function WithManageCookiesData ({
  children,
}) {
  const [cookiesData, setCookiesData] = React.useState(initialCookiesData);
  const [selected, setSelected] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(true);
  const [cookies, setCookie] = useCookies([cookieKey]);

  React.useEffect(() => {
    const { vendor } = cookies;
    if (vendor) {
      const { selected } = vendor;
      setSelected(selected);
      setShowDialog(false);
    }
  }, []);

  React.useEffect(() => {
    if (selected) {
      const { vendor } = cookies;
      const today = new Date();
      const expires = new Date(today.setFullYear(today.getFullYear() + 1));
      setCookie(cookieKey, JSON.stringify({...vendor, selected}), { path: '/', expires });
    }
  }, [selected])

  React.useEffect(() => {
    if(cookiesData !== initialCookiesData){
      const today = new Date();
      const expires = new Date(today.setFullYear(today.getFullYear() + 1));
      setCookie(cookieKey, JSON.stringify({...cookiesData, selected}), { path: '/', expires });
    }
  }, [cookiesData]);

  React.useEffect(() => {
    if (cookiesData.preferences_cookie) {
      // const tagManagerArgs = {
      //   gtmId: process.env.REACT_APP_GTM_ID,
      // };
      // TagManager.initialize(tagManagerArgs);

      const options = {
        autoConfig: true,
        debug: false,
      };
      ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID, null, options);
    }
  }, [cookiesData]);

  return (
    <ManageCookiesContext.Provider
      value={{ cookiesData, setCookiesData, selected, setSelected, showDialog, setShowDialog  }}
    >
      {children}
    </ManageCookiesContext.Provider>
  );
}
