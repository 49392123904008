/* eslint-disable */
import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import promise from 'redux-promise-middleware';
import { routerMiddleware } from 'react-router-redux';
import { createLogger } from 'redux-logger';
import reducers from '../reducers';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

const middleware = applyMiddleware(promise, thunk, routerMiddleware(createBrowserHistory()));

const persistConfig = {
    key: 'vendor',
    storage,
    whitelist: [
        // 'wizardState'
    ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })) || compose;

const store = createStore(persistedReducer, composeEnhancers(middleware));
const persistor = persistStore(store);

// export default store;
export { store, persistor };
