// import { palette } from 'services/theme/theme-constants';
import { palette } from './../../theme/theme-constants';

export const lists = {
    MuiListItem: {
        root: {
            '&:not(:last-child)': {
                borderBottom: `1px solid ${palette.border.separator}`,
            },
        },
    },
};
