import React, { useReducer, useMemo, useContext } from 'react';
import { themeReducer } from './theme-reducer';
import { INITIAL_STATE } from './theme-constants';

const ThemeContext = React.createContext();

export const ThemeProvider = ({ children }) => {
    const [state, dispatch] = useReducer(themeReducer, INITIAL_STATE);

    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);

    return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};

export const useThemeState = () => {
    const { state } = useContext(ThemeContext);
    return state;
};

export const useThemeDispatch = () => {
    const { dispatch } = useContext(ThemeContext);
    return dispatch;
};
