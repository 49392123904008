import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import paymentCardIcon from '../../../../assets/icons/payment-icon-card.svg';
import paymentInvoiceIcon from '../../../../assets/icons/payment-icon-invoice.svg';

import { IsPhone } from '../../../../services/CustomMediaQueries';

import RadioButton from '../../inputs/RadioButton';
import TextInput from '../../inputs/TextInput';
import CustomPhoneInput from '../../inputs/CustomPhoneInput';
import Summary from './step-6/Summary';
import { ENTITY } from '../../../../services/WizardStateConstants';
import { POST_NEW_ORDER } from '../../../../services/api';

import ApiExecutor from '../../../../services/Api/ApiExecutor'
import InputErrorWrapper from '../../inputs/InputErrorWrapper';
import { validateEmail } from '../../../../services/StringHelpers';

function Step6({ data, stepIndex, wizardStateData, updateWizardStateStep }) {

    // const wizardStateData = useSelector(state => state.wizardState.data);

    const styles = {
        payButton: {
            background: '#eb1e5f',
            borderRadius: '25px',
            color: '#FFFFFF',
            borderWidth: 0,
            padding: '5px 25px',
            fontWeight: 300,
            fontSize: 18
        }
    }

    // When redirected from CASYS
    const [paymentFailed, setPaymentFailed] = useState(false);
    useEffect(() => {
        var searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('paymentSuccessful')) {
            const paymentSuccessful = searchParams.get('paymentSuccessful');
            setPaymentFailed(paymentSuccessful !== 'true');
        }
    }, []);

    /* eslint-disable */
    const [fix, setFix] = useState('');

    var invoiceInputs = data.inputs[3].options;
    var summaryLabels = data.inputs[0].labels;

    const handlePaymentRadioButtonChange = (index) => {
        wizardStateData.steps[stepIndex].selectedPaymentIndex = index;
        updateWizardStateStep(stepIndex);
        setFix(index);
    }

    const handleEntityRadioButtonChange = (index) => {
        wizardStateData.steps[stepIndex].selectedEntityIndex = index;
        updateWizardStateStep(stepIndex);
        setFix(index);
    }

    const handleTextChange = (value, index) => {
        const key = Object.keys(wizardStateData.steps[stepIndex])[index];
        wizardStateData.steps[stepIndex][key] = value;
        setFix(value);
    }

    const isPhone = IsPhone();
    const [cpayFormData, setCpayFormData] = useState({});
    // const [newOrderData, setNewOrderData] = useState({});

    //
    useState(() => {
        if (wizardStateData.steps[stepIndex].firstName === '')
            wizardStateData.steps[stepIndex].firstName = wizardStateData.steps[0].firstName;
        if (wizardStateData.steps[stepIndex].lastName === '')
            wizardStateData.steps[stepIndex].lastName = wizardStateData.steps[0].lastName;
        if (wizardStateData.steps[stepIndex].phone === '')
            wizardStateData.steps[stepIndex].phone = wizardStateData.steps[0].phone;
        if (wizardStateData.steps[stepIndex].email === '')
            wizardStateData.steps[stepIndex].email = wizardStateData.steps[0].email;
    }, [])

    const handlePay = async () => {
        var successfulValidation = validate();
        if (!successfulValidation) {
            wizardStateData.steps[stepIndex].successfulValidation = false;
            updateWizardStateStep(stepIndex);
            return;
        }

        const firstName = wizardStateData.steps[stepIndex].selectedEntityIndex === ENTITY.Person ? wizardStateData.steps[0].firstName : wizardStateData.steps[5].firstName;
        const lastName = wizardStateData.steps[stepIndex].selectedEntityIndex === ENTITY.Person ? wizardStateData.steps[0].lastName : wizardStateData.steps[5].lastName;
        const phone = wizardStateData.steps[stepIndex].selectedEntityIndex === ENTITY.Person ? wizardStateData.steps[0].phone : wizardStateData.steps[5].phone;
        const email = wizardStateData.steps[stepIndex].selectedEntityIndex === ENTITY.Person ? wizardStateData.steps[0].email : wizardStateData.steps[5].email;

        const newOrderData = {
            Plan: wizardStateData.steps[4].planIndex,
            FirstName: firstName,
            LastName: lastName,
            PhoneNumber: phone,
            Email: email,
            Address: wizardStateData.steps[5].address,
            City: wizardStateData.steps[5].city,
            Zip: wizardStateData.steps[5].zip,
        }
        let response = await ApiExecutor('post', POST_NEW_ORDER, newOrderData);

        const newCpayData = { ...newOrderData, ...response['data'] };
        setCpayFormData(newCpayData);
        handleSubmit();


    }

    const handleSubmit = async () => {
        setTimeout(function () {
            console.log("Ready")

            // document.getElementById('cpay_form').submit();
        }, 500);
    }

    const validate = () => {
        // If company selected
        if (wizardStateData.steps[stepIndex].selectedEntityIndex === 0) {
            if (wizardStateData.steps[5].firstName === '')
                return false;
            if (wizardStateData.steps[5].lastName === '')
                return false;
            if (wizardStateData.steps[5].phone.length < 11)
                return false;
            if (!validateEmail(wizardStateData.steps[5].email))
                return false;
        }

        if (wizardStateData.steps[5].address === '')
            return false;
        if (wizardStateData.steps[5].city === '')
            return false;
        if (wizardStateData.steps[5].zip === '')
            return false;
        if (wizardStateData.steps[5].edb.length != 13)
            return false;

        return true;
    }

    const showErrorMessage = (index) => {
        if (wizardStateData.steps[stepIndex].successfulValidation)
            return false;

        if (index === 0)
            return wizardStateData.steps[stepIndex].firstName === '';
        else if (index === 1)
            return wizardStateData.steps[stepIndex].lastName === '';
        else if (index === 2)
            return !validateEmail(wizardStateData.steps[stepIndex].email);
        else if (index === 3)
            return wizardStateData.steps[stepIndex].phone.length < 11;
        else if (index === 4)
            return wizardStateData.steps[stepIndex].address === '';
        else if (index === 5)
            return wizardStateData.steps[stepIndex].zip === '';
        else if (index === 6)
            return wizardStateData.steps[stepIndex].city === '';
        else if (index === 7)
            return wizardStateData.steps[stepIndex].company === '';
        else if (index === 8)
            return wizardStateData.steps[stepIndex].edb === '';
    }


    const getValue = (index) => {
        const key = Object.keys(wizardStateData.steps[stepIndex])[index];
        return wizardStateData.steps[stepIndex][key];
    }

    return (
        <div className="mt-3">
            <div class="row mx-0">

            <div class="col-md-6">
            <Form.Group controlId="paymentType" className={isPhone ? "mt-4" : "mt-4 pl-4 pr-4"}>
                {/* Payment method */}
                <Form.Label className="mb-3" >{data.inputs[1].label}</Form.Label>
                <RadioButton onClick={() => handlePaymentRadioButtonChange(0)} checked={wizardStateData.steps[stepIndex].selectedPaymentIndex === 0} id={0} label={data.inputs[1].оptions[0]} iconSource={paymentCardIcon} iconAlt='Card icon' />
                <hr className="custom-hr" />
                <RadioButton className="mb-5" onClick={() => handlePaymentRadioButtonChange(1)} checked={wizardStateData.steps[stepIndex].selectedPaymentIndex === 1} id={1} label={data.inputs[1].оptions[1]} iconSource={paymentInvoiceIcon} iconAlt='Invoice icon' />

                {/* Person or company */}
                <Form.Label className="mb-3" >{data.inputs[2].label}</Form.Label>
                <RadioButton onClick={() => handleEntityRadioButtonChange(0)} checked={wizardStateData.steps[stepIndex].selectedEntityIndex === 0} id={3} label={data.inputs[2].оptions[0]} />
                <hr className="custom-hr" />
                <RadioButton className="mb-5" onClick={() => handleEntityRadioButtonChange(1)} checked={wizardStateData.steps[stepIndex].selectedEntityIndex === 1} id={4} label={data.inputs[2].оptions[1]} />

                {/* Invoice payment */}
                {/* {wizardStateData.steps[stepIndex].selectedPaymentIndex === 1 && ( */}
                <div>
                    {/* Fields only for company */}
                    {wizardStateData.steps[stepIndex].selectedEntityIndex === 0 && (
                        <>
                            <div className="row">
                                <div className="col-sm-6">
                                    <InputErrorWrapper id={"Step1-input-" + 0} className="mb-4" errorMessage={invoiceInputs[0].error_message} showError={showErrorMessage(0)}>
                                        <TextInput label={invoiceInputs[0].label} value={getValue(0)} placeholder={invoiceInputs[0].placeholder} onChange={handleTextChange} required={invoiceInputs[0].required} index={0} />
                                    </InputErrorWrapper>
                                </div>
                                <div className="col-sm-6">

                                    <InputErrorWrapper id={"Step1-input-" + 1} className="mb-4" errorMessage={invoiceInputs[1].error_message} showError={showErrorMessage(1)}>
                                        <TextInput label={invoiceInputs[1].label} value={getValue(1)} placeholder={invoiceInputs[1].placeholder} onChange={handleTextChange} required={invoiceInputs[1].required} index={1} />
                                    </InputErrorWrapper>
                                </div>
                            </div>

                            <InputErrorWrapper id={"Step1-input-" + 2} className="mb-4" errorMessage={invoiceInputs[2].error_message} showError={showErrorMessage(2)}>
                                <TextInput label={invoiceInputs[2].label} value={getValue(2)} placeholder={invoiceInputs[2].placeholder} onChange={handleTextChange} required={invoiceInputs[2].required} index={2} />
                            </InputErrorWrapper>

                            <InputErrorWrapper id={"Step1-input-" + 3} className="mb-4" errorMessage={invoiceInputs[3].error_message} showError={showErrorMessage(3)}>
                                <CustomPhoneInput label={invoiceInputs[3].label} value={getValue(3)} onChange={handleTextChange} showError={showErrorMessage(3)} required={invoiceInputs[3].required} index={3} />
                            </InputErrorWrapper>
                        </>
                    )}

                    <InputErrorWrapper id={"Step1-input-" + 4} className="mb-4" errorMessage={invoiceInputs[4].error_message} showError={showErrorMessage(4)}>
                        <TextInput label={invoiceInputs[4].label} value={getValue(4)} placeholder={invoiceInputs[4].placeholder} onChange={handleTextChange} required={invoiceInputs[4].required} index={4} />
                    </InputErrorWrapper>
                    <div className="row">

                        <div className="col-sm-6">

                            <InputErrorWrapper id={"Step1-input-" + 5} className="mb-4" errorMessage={invoiceInputs[5].error_message} showError={showErrorMessage(5)}>
                                <TextInput label={invoiceInputs[5].label} value={getValue(5)} placeholder={invoiceInputs[5].placeholder} onChange={handleTextChange} required={invoiceInputs[5].required} index={5} />
                            </InputErrorWrapper>
                        </div>
                        <div className="col-sm-6">

                            <InputErrorWrapper id={"Step1-input-" + 6} className="mb-4" errorMessage={invoiceInputs[6].error_message} showError={showErrorMessage(6)}>
                                <TextInput label={invoiceInputs[6].label} value={getValue(6)} placeholder={invoiceInputs[6].placeholder} onChange={handleTextChange} required={invoiceInputs[6].required} index={6} />
                            </InputErrorWrapper>
                        </div>
                    </div>
                    {/* Fields only for company  */}
                    {wizardStateData.steps[stepIndex].selectedPaymentIndex === 1 && (
                        <>
                            <InputErrorWrapper id={"Step1-input-" + 7} className="mb-4" errorMessage={invoiceInputs[7].error_message} showError={showErrorMessage(7)}>
                                <TextInput label={invoiceInputs[7].label} value={getValue(7)} placeholder={invoiceInputs[7].placeholder} onChange={handleTextChange} required={invoiceInputs[7].required} index={7} />
                            </InputErrorWrapper>


                            <InputErrorWrapper id={"Step1-input-" + 8} className="mb-4" errorMessage={invoiceInputs[8].error_message} showError={showErrorMessage(8)}>
                                <TextInput label={invoiceInputs[8].label} value={getValue(8)} placeholder={invoiceInputs[8].placeholder} onChange={handleTextChange} required={invoiceInputs[8].required} index={8} />
                            </InputErrorWrapper>
                        </>
                    )}
                </div>
                {/* )} */}
            </Form.Group>

            <form id='cpay_form' action='https://www.cpay.com.mk/client/Page/default.aspx?xml_id=/mk-MK/.loginToPay/.simple/' method='post'>
                <input id='cpayAmountToPay' name='AmountToPay' value={cpayFormData['AmountToPay']} type="hidden" />
                <input id='cpayPayToMerchant' name='PayToMerchant' value='1000001665' type="hidden" />
                <input id='cpayMerchantName' name='MerchantName' value='ALTIUS SOLUSHN DOOEL' type="hidden" />
                <input id='cpayAmountCurrency' name='AmountCurrency' value={cpayFormData['AmountCurrency']} type="hidden" />
                <input id='cpayDetails1' name='Details1' value={cpayFormData['Details1']} type="hidden" />
                <input id='cpayDetails2' name='Details2' value={cpayFormData['Details2']} type="hidden" />
                <input id='cpayPaymentOKURL' size='10' name='PaymentOKURL' value='https://vendor-react.vercel.app/wizard?paymentSuccessful=true' type="hidden" />
                <input id='cpayPaymentFailURL' size='10' name='PaymentFailURL' value='https://vendor-react.vercel.app/wizard?paymentSuccessful=false' type="hidden" />
                <input id='cpayCheckSumHeader' name='CheckSumHeader' value={cpayFormData['CheckSumHeader']} type="hidden" />
                <input id="CheckSum" name="CheckSum" value={cpayFormData['CheckSum']} type="hidden" />
                <input id='cpayFirstName' size='10' name='FirstName' value={cpayFormData['FirstName']} type="hidden" />
                <input id='cpayLastName' size='10' name='LastName' value={cpayFormData['LastName']} type="hidden" />
                <input id='cpayAddress' size='10' name='Address' value={cpayFormData['Address']} type="hidden" />
                <input id='cpayCity' size='10' name='City' value={cpayFormData['City']} type="hidden" />
                <input id='cpayZip' size='10' name='Zip' value={cpayFormData['Zip']} type="hidden" />
                <input id='cpayCountry' size='10' name='Country' value='Macedonia' type="hidden" />
                <input id='cpayTelephone' size='10' name='Telephone' value={cpayFormData['PhoneNumber']} type="hidden" />
                <input id='cpayEmail' size='10' name='Email' value={cpayFormData['Email']} type="hidden" />
                <input id='cpayOriginalAmount' name='OriginalAmount' value={cpayFormData['OriginalAmount']} type="hidden" />
                <input id='cpayOriginalCurrency' name='OriginalCurrency' value={cpayFormData['OriginalCurrency']} type="hidden" />
                {/* <input class='button' value='Pay' type='submit' /> */}
            </form>
            {paymentFailed && (
                <div className="justify-content-center d-flex">
                    Плаќањето е неуспешно! Ве молиме обидете се повторно!
                </div>
            )
            }
            <div className="d-flex justify-content-center mt-5">
                <button style={styles.payButton} onClick={() => { handlePay() }} >
                    Плати
                </button>
            </div>
            </div>
            <Summary summaryLabels={summaryLabels} />

        </div>
        </div >
    )

}

export default Step6;


