/* eslint-disable */
import React from 'react';

import Lottie from "lottie-react";

import idleRobot from "../../../assets/animations/head-idle.json";

import WizardSteps from './WizardSteps'
import { IsDesktop, IsPhone, IsTablet } from '../../../services/CustomMediaQueries';
import NavigationButtons from './NavigationButtons';
import { CustomParser } from '../../../services/CustomParser';


function WizardFormGenerator({ data, stepIndex, wizardStateData, updateWizardStateStep, updateWizardState }) {

    let lottieWidth = 150;
    let styles = {
        desktop: {
            wrapper: {
                paddingTop: 40,
                paddingBottom: 40,
                // paddingLeft: 120,
                // paddingRight: 120,
                // paddingLeft: 150,
                // paddingRight: 150,
                minHeight: '400px',

                maxWidth: '70%',
                margin: '0 auto'
            },
            description: {
                fontSize: 17,
                whiteSpace: 'pre-line'
            },
        },
        tablet: {
            wrapper: {
                paddingTop: 40,
                paddingBottom: 40,
                paddingLeft: 50,
                paddingRight: 50,
                minHeight: '400px',
            },
            description: {
                fontSize: 17,
            },
        },
        phone: {
            wrapper: {
                paddingTop: 40,
                paddingBottom: 40,
                paddingLeft: 20,
                paddingRight: 20,
                minHeight: '400px',
            },
            description: {
                fontSize: 17,
            },
        }
    }

    const isTablet = IsTablet();
    const isDesktop = IsDesktop();
    const isPhone = IsPhone();


    return (
        <div className="row position-relative">
            <div className="justify-content-center w-100" style={isDesktop ? styles.desktop.wrapper : (isTablet ? styles.tablet.wrapper : styles.phone.wrapper)}>
                {/* Title */}
                <h3 className="custom-label text-center mb-4" style={{ fontSize: '2rem' }}>
                    {data[stepIndex].title}
                </h3 >
                {/* Description */}
                <p className="c-gray text-center fw-100 mb-5" style={styles.desktop.description} >
                    {/* Gore: mb-5 */}
                    {data[stepIndex].description}
                </p >
                {/* Inputs */}
                <WizardSteps data={data} stepIndex={stepIndex} wizardStateData={wizardStateData} updateWizardState={updateWizardState} updateWizardStateStep={updateWizardStateStep} />


            </div>
        </div>
    );
}

export default WizardFormGenerator;
