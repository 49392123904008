/* eslint-disable */
import parse from "html-react-parser";

function Section3(props) {
  let features = props.features;

  return (
    <div className="container pt-4">
      {features.map((feature, index) => {
        return (
          <div key={index}>
            {feature["image-alignment"] === "left" && (
              <div className="row zig-zag-wrapper my-4">
                <div className="col-md-6 d-flex align-items-center">
                  <div className="text">
                    <h1 className="zig-zag-heading text-left mb-40 ">
                      {parse(feature.title)}
                    </h1>
                    <div className="zig-zag-description text-left zig-zag-checkmarked-list disable-br-desktop">
                      {parse(feature.text)}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 benefit-img">
                  {/* <img height={'407px'} src={feature.image} alt={feature.title} /> */}
                  <video
                    style={{ width: "100%", marginTop: 10 }}
                    loop
                    playsInline
                    autostart="true"
                    autoPlay
                    muted
                    type="video/mp4"
                    poster={feature.image}
                    src={feature.video}
                  ></video>
                </div>
              </div>
            )}

            {feature["image-alignment"] === "right" && (
              <div className="row zig-zag-wrapper my-4">
                <div className="col-md-6 benefit-img">
                  {/* <img height={'407px'} src={feature.image} alt={feature.title} /> */}
                  <video
                    style={{ width: "100%", marginTop: 10 }}
                    loop
                    playsInline
                    autostart="true"
                    autoPlay
                    muted
                    type="video/mp4"
                    poster={feature.image}
                    src={feature.video}
                  ></video>
                </div>
                <div className="col-md-6  d-flex align-items-center">
                  <div className="text">
                    <h1 className="zig-zag-heading text-left mb-40 ">
                      {parse(feature.title)}
                    </h1>
                    <div className="zig-zag-description text-left zig-zag-checkmarked-list disable-br-desktop">
                      {parse(feature.text)}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default Section3;
