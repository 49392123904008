/* eslint-disable */
import React from 'react';
import { DropdownButton, Form, InputGroup, Dropdown } from "react-bootstrap";
import ErrorMessage from './ErrorMessage';

function InputErrorWrapper({ children, className, errorMessage, showError, id, errorAlignCenter }) {

    return (
        <div style={{ position: 'relative' }} className={className} id={id} >
            {children}
            {showError && (
                <ErrorMessage errorMessage={errorMessage} style={{ textAlign: errorAlignCenter ? 'center' : 'left' }} />
            )}
        </div>
    )
}

export default InputErrorWrapper;
