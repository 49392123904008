/* eslint-disable */
import { useHistory } from "react-router-dom";
import { Wave } from "react-animated-text";

function Section1(props) {
  const { push } = useHistory();
  return (
    <div className="my-3">
      <div className="container big-container mb-md-0 mb-5">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 col-sm-12 order-md-1 order-2">
            <h1 className="ff-literal fs-36 lh-42 pb-4 c-indigo max-width-750 ml-auto mr-auto">
              {props.title}
            </h1>
            {/* <p className="fw-100 fs-22 lh-22 pb-1 c-darker-blue max-width-750 ml-auto mr-auto mb-0">
              <strong>{props.tagline}</strong>
            </p> */}
            {/* <p className="fw-100 fs-22 lh-31 pb-1 c-indigo max-width-750 ml-auto mr-auto mb-3">
              <strong>{props.tagline_1}</strong>
            </p> */}
            {/* <p className="fw-100 fs-18 lh-28 c-gray mb-4 max-width-750 ml-auto mr-auto mt-0">
              {parse(props.tagline2)}
            </p> */}
            <div className="">
              <a
                className="pink-button"
                id="hero-register-button"
                href="/register-business"
              >
                {props.button_label}
              </a>
            </div>
            {/* <img className="main-hero-image" height={"100%"} width={"100%"} src={props.img} alt={"img"} /> */}
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12 order-md-2 order-1">
            <video
              style={{ width: "100%", marginTop: 10 }}
              autostart="true"
              autoPlay
              muted
              type="video/mp4"
              poster="/vendor-hero.jpg"
              src="/vendor-header.mp4"
            ></video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section1;
