import React from 'react';
import { Form } from "react-bootstrap";

function RadioButton({ checked, label, onClick, id, className, iconSource = null, iconAlt = '' }) {
    return (
        <div className={"position-relative " + className} onClick={() => onClick()}>
            <Form.Check className="payment-radiobutton" type="radio" checked={checked} id={id} label={label} />
            {iconSource != null && (
                <img className="payment-icon" src={iconSource} alt={iconAlt} />
            )}
        </div>
    )
}

export default RadioButton;