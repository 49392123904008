import GreenwichRegular from './../../../assets/fonts/Greenwich-Regular.otf';
import GreenwichLight from './../../../assets/fonts/Greenwich_Light.otf';
import GreenwichMedium from './../../../assets/fonts/Greenwich-Medium.otf';
import GreenwichBold from './../../../assets/fonts/Greenwich_Bold.otf';

export const gw_regular = {
  fontFamily: 'Greenwich',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      local('Greenwich_Regular'),
      url(${GreenwichRegular}) format('opentype')
    `,
};

export const gw_light = {
  fontFamily: 'Greenwich',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 200,
  src: `
      local('Greenwich_Light'),
      url(${GreenwichLight}) format('opentype')
    `,
};

export const gw_medium = {
  fontFamily: 'Greenwich',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'bold',
  src: `
      local('Greenwich_Medium'),
      url(${GreenwichMedium}) format('opentype')
    `,
};

export const gw_bold = {
  fontFamily: 'Greenwich',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 900,
  src: `
      local('Greenwich_Bold'),
      url(${GreenwichBold}) format('opentype')
    `,
};
