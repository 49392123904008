import { ActionStatus } from '../../core/ActionStatus';
import { buildActionType } from '../utils/buildActionType';

const ActionExecutor = (apiRequest, action) => async dispatch =>
    new Promise(async (resolve, reject) => {
        dispatch({ type: buildActionType(action, ActionStatus.START) });

        let response = await apiRequest;

        if (response.success) {
            dispatch({
                type: buildActionType(action, ActionStatus.DONE),
                payload: response.data,
                responseStatus: response.status,
            });
            resolve(response);
            // dispatch({ type: NOTIFICATION, payload: { message: `${SuccessNotification[LOGIN.action]} logged in`, variant: NotificationType.Success } });
        } else {
            dispatch({
                type: buildActionType(action, ActionStatus.FAILED),
                payload: response.data,
                responseStatus: response.status,
            });
            reject(response);
        }
    });

export default ActionExecutor;
