import React from 'react';
import Lottie from "lottie-react";

import iddleAnim from "../../../../assets/animations/idle.json";
import waveAnim from "../../../../assets/animations/wave.json";
import thumbsUpAniim from "../../../../assets/animations/thumbup.json";

function Robot({ state, width }) {
    const robotStates = {
        iddle: 'idle',
        wave: 'wave',
        thumbsUp: 'thumbs_up'
    }

    switch (state) {
        case robotStates.idle:
            return <Lottie style={{ width: width }} animationData={iddleAnim} />;
        case robotStates.wave:
            return <Lottie style={{ width: width }} animationData={waveAnim} />;
        case robotStates.thumbsUp:
            return <Lottie style={{ width: width }} animationData={thumbsUpAniim} />;
        default:
            return <Lottie style={{ width: width }} animationData={iddleAnim} />;

    }

}

export default Robot;