/* eslint-disable */
import React from "react";
import logoFb from '../../../assets/images/footer-logo-facebook.svg';
import logoInsta from '../../../assets/images/footer-logo-instagram.svg';
import logoTwitter from '../../../assets/images/footer-logo-twitter.svg';
import logoYt from '../../../assets/images/footer-logo-youtube.svg';
import logoLinked from '../../../assets/images/footer-logo-linkedin.svg';
import logoWhite from '../../../assets/images/vendor-logo-white.svg';



import data from '../../../assets/api/mk/front.json'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Footer() {
  const { t } = useTranslation();
  let menu = data?.footer?.menus;
  let social_media = data?.footer?.social_media;

  return (
    <div>
      <footer className="bg-dark-blue pt-5 pb-4">
        <div className="container">
          {/* first part of footer */}
          <div className="row first-part-footer">
            <div class="row mx-0 col-md-12">
            <div class="col-md-12 text-center">
            <div className="col-md-3 mx-auto footer-logo-social mb-5">
              <div className="footer-logo-wrapper">
                <img alt={"logoWhite"} width={'190px'} src={logoWhite}/>
              </div>
            </div>
            </div>
            <div class="col-md-12">
              <div class="row mx-0">
            {
              menu.map((foot1, index) => {
                return (
                  <div key={index} className="col-md-3 col-sm-6 footer-col">
                    <div className="footer-links">
                      <h6>{foot1.menu_title}</h6>
                      {
                        foot1.links.map((links, index) => {
                          return (
                            <p key={index}><a target={links.target} href={links.slug}>{links.title}</a></p>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
            </div>
            </div>

            <div class="col-md-12 text-center">
            <div className="col-md-2  mx-auto footer-logo-social mb-3">
        
              <div className='d-flex footer-social-row'>
                <a href={social_media?.facebook} target="_blank" rel="noreferrer"><img width={'30px'} height={'30px'}
                                                                                       src={logoFb} alt={"logoFb"}/></a>
                <a href={social_media?.instagram} target="_blank" rel="noreferrer"><img width={'30px'} height={'30px'}
                                                                                        src={logoInsta}
                                                                                        alt={"logoInsta"}/></a>
                <a href={social_media?.linkedin} target="_blank" rel="noreferrer"><img width={'30px'} height={'30px'}
                                                                                       src={logoLinked} alt={"logoLinkedin"}/></a>                                                                        
                <a href={social_media?.twitter} target="_blank" rel="noreferrer"><img width={'30px'} height={'30px'}
                                                                                      src={logoTwitter}
                                                                                      alt={"logoTwitter"}/></a>
                <a href={social_media?.youtube} target="_blank" rel="noreferrer"><img width={'30px'} height={'30px'}
                                                                                      src={logoYt} alt={"logoYt"}/></a>
              </div>
            </div>
            </div>

          </div>
          </div>

          <div className="row">
            <div className="w-100 d-flex second-part-footer text-center">
                <h6 className="text-center w-100">{data.footer.copyright_text}</h6>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
