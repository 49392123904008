import React from 'react';
import { Form, InputGroup } from "react-bootstrap";
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'

function CustomPhoneInput({ label, value, onChange, required, index, showError }) {
    return (
        <InputGroup hasValidation className="mb-4 flex-column">
            <Form.Label>{label}</Form.Label>
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-betwen' }}>
                <PhoneInput
                    style={{ display: 'flex' }}
                    country={'mk'}
                    international
                    value={value}
                    onChange={(value) => { onChange(value, index) }}
                    required={required}
                    masks={{ mk: '.. ... ...', }}
                    inputStyle={showError ? { borderColor: '#dc3545' } : {}}
                    buttonStyle={{ height: '95%', marginTop: '1px', marginLeft: '1px' }}
                />
            </div>
        </InputGroup>
    )
}

export default CustomPhoneInput;