/* eslint-disable */
import shapedWhiteBg from "../../../assets/images/bg-shape-crooked.png";
import Lottie from "lottie-react";
import thumbsUpRobot from "../../../assets/animations/wave.json";
import parse from "html-react-parser";

function Section5(props) {
  return (
    <div className="bg-dark-blue pt-90 meet-boro-wrapper">
      <div className="container position-relative">
        <div className="row pb-5 meet-boro-row-wrapper">
          <div className="col-md-8 col-sm-8 meet-boro-text-wrapper">
            <img
              src="/virtual-assistant-logo.svg"
              alt="Vendor Virtual Assistant - Logo"
              width="300"
              class="mb-4"
            />

            <h2 className="fs-26 lh-36 text-white text-left  mb-3 ">
              {parse(props.title)}
            </h2>
            <p className="fw-100 fs-22 lh-31 fs-21 text-left c-lavander ">
              {props.text}
            </p>
          </div>

          <div className="col-md-4 col-sm-4 robot-wrap">
            <Lottie
              className="meet-boro-lottie"
              animationData={thumbsUpRobot}
            />
          </div>
        </div>
        <div className="wizard-flow-wrapper">
          <img
            src={props.robotWizardFlow}
            className="robot-wizard-flow"
            alt={"robot wizard flow"}
          />
        </div>
      </div>
      <img
        src={shapedWhiteBg}
        className="w-100 shaped-white-bg"
        style={{ height: "105px" }}
        alt={"shapedWhiteBg"}
      />
    </div>
  );
}

export default Section5;
