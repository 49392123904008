/* eslint-disable */
import Slider from "react-slick";

function Section2(props) {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  };

  console.log("before" + props.testimonials[0].image)

  let testimonials = props.testimonials;

  return (
    <div id="carousel-sc-2" className="container">
      <Slider {...settings} className="p-4">
        {
          testimonials.map((car, index) => {
            let toFormatImageUrl = car?.image;
            let imageUrl = toFormatImageUrl.replace(' ', '%20');

            return (
              <div key={index} className="d-flex justify-content-center align-items-center position-relative h-410 testimonials-carousel-wrapper" >
                <div className='carousel-img-wrapper'>
                  <img style={{ objectFit: 'cover', borderRadius: 20 }} className="testimonial-img" width={'235px'} height={'265px'} src={imageUrl} alt={car.name} />
                  <div className="logo-name-mobile">
                    <img className="carousel-testimonials-logo mt-2 mb-2" width={'100px'} src={car.logo} alt={"logo"} />
                    <p className="carousel-name-position mb-3">{car.name},
                      {car.position &&
                        <span style={{ color: '#eb1e5f' }}> {car.position}</span>
                      }</p>
                  </div>
                </div>

                <div className="carousel-right-wrapper">
                  <div className="carousel-right">
                    <div className="logo-name-desktop">
                      <img className="carousel-testimonials-logo" width={'150px'} src={car.logo} alt={"bank logo"} />
                      <p className="carousel-name-position mb-3">{car.name}, 
                      {car.position &&
                        <span style={{ color: '#eb1e5f' }}> {car.position}</span>
                      }</p>
                    </div>
                    <div className="carousel-heading mb-4" dangerouslySetInnerHTML={{ __html: car.tagline }}></div>
                    <div dangerouslySetInnerHTML={{ __html: car.text }}></div>
                  </div>
                </div>
              </div>
            )
          })
        }

      </Slider>
    </div>
  )
}

export default Section2;
