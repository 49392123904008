/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Form, InputGroup, Dropdown } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import RadioButton from '../../inputs/RadioButton';
import ErrorMessage from '../../inputs/ErrorMessage';


function Step4({ data, stepIndex, wizardStateData, updateWizardStateStep }) {

    const [fix, setFix] = useState('');
    // const [postfixIndex, setPostfixIndex] = useState('');

    const handleDomainTypeIndexChange = (index) => {
        wizardStateData.steps[stepIndex].selectedIndex = index;
        setFix(index);
    }

    const handleFreeDomainChange = (event) => {
        wizardStateData.steps[stepIndex].freeDomainValue = event.target.value;
        setFix(event.target.value);
    }

    const handlePersonalDomainChange = (event) => {  // Stays
        wizardStateData.steps[stepIndex].yourDomainValue = event.target.value;
        wizardStateData.steps[stepIndex].domainAvailable = true;        // To hide the error messaage.
        updateWizardStateStep(stepIndex);
        setFix(event.target.value);
    }
    const handlePersonalDomainPostfixChange = (index) => {
        wizardStateData.steps[stepIndex].yourDomainPostfixIndex = index;
        wizardStateData.steps[stepIndex].postfixValue = data.inputs[1].postfixOptions[index];
        updateWizardStateStep(stepIndex);
        // setPostfixIndex(index);
        setFix(index);
    }

    const handleExistingDomainChange = (event) => {  // Stays
        wizardStateData.steps[stepIndex].existingDomain = event.target.value;
        updateWizardStateStep(stepIndex);
        setFix(event.target.value);
    }

    useEffect(() => {
        wizardStateData.steps[stepIndex].postfixValue = data.inputs[1].postfixOptions[0];
        setFix(fix + 1);
    }, [])

    // New
    const handleDropdownChange = (index) => {
        wizardStateData.steps[stepIndex].selectedDropdownIndex = index;
        setFix(index);
    }

    const showErrorMessage1 = wizardStateData.steps[stepIndex].selectedDropdownIndex === 0 && !wizardStateData.steps[stepIndex].successfulValidation && (wizardStateData.steps[stepIndex].yourDomainValue.length == 0 || wizardStateData.steps[stepIndex].domainAvailable);
    const showErrorMessage2 = wizardStateData.steps[stepIndex].selectedDropdownIndex === 1 && !wizardStateData.steps[stepIndex].successfulValidation && wizardStateData.steps[stepIndex].existingDomain.length == 0;

    return (
        <Form.Group controlId="customRadioButtons" style={{ marginTop: '50px' }} >
            {/* Dropdown */}
            <>
                {/* <Form.Label className="mb-3">Избери</Form.Label> */}
                {data.inputs[0].options.map((item, index) => (
                    <>
                        <RadioButton className="mb-1" onClick={() => handleDropdownChange(index)} checked={wizardStateData.steps[stepIndex].selectedDropdownIndex === index} id={'Step4-input' + index} label={item} />
                        {/* {data.inputs[0].options.length - 1 > index && (
                            <hr className="custom-hr" />
                        )} */}
                    </>
                ))}
            </>

            {/* New domain */}
            {wizardStateData.steps[stepIndex].selectedDropdownIndex === 0 && (
                <div className="mt-5 mb-3" >
                    {/* <Form.Label>{data.inputs[1].label}</Form.Label> */}
                    <div className="row align-items-end pl-3 pr-3 position-relative col-md-10 mx-auto" >

                        <TextField id="standard-basic" disabled={wizardStateData.steps[stepIndex].selectedDropdownIndex !== 0} label="" focused={wizardStateData.steps[stepIndex].selectedDropdownIndex === 0} placeholder={data.inputs[1].placeholder} value={wizardStateData.steps[stepIndex].yourDomainValue} style={showErrorMessage1 ? { flexGrow: 1, borderColor: '#dc3545' } : { flexGrow: 1 }} onChange={e => handlePersonalDomainChange(e)} />

                        <div className="dropdown postfix-domain-dropdown ml-3"  >
                            <button
                                className="btn-sm btn-outline-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{ width: '120px' }}
                                disabled={wizardStateData.steps[stepIndex].selectedDropdownIndex !== 0}
                            >
                                <span style={{ alignSelf: 'flex-start' }}>
                                    {data.inputs[1].postfixOptions[wizardStateData.steps[stepIndex].yourDomainPostfixIndex]}
                                </span>
                            </button>

                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton"  >
                                {data.inputs[1].postfixOptions.map((item, index) => (
                                    <button id={index} className="dropdown-item btn-link" onClick={() => handlePersonalDomainPostfixChange(index)} type="button">
                                        <span className="dropdown-text">
                                            {item}
                                        </span>
                                    </button>
                                ))}
                            </div>
                        </div>
                        {/* Custom Error message */}
                        {showErrorMessage1 && (
                            <ErrorMessage errorMessage={data.inputs[1].error_message} />
                        )}
                    </div>
                </div>
            )}
            {wizardStateData.steps[stepIndex].selectedDropdownIndex === 1 && (
                <div className="mt-5 mb-3 position-relative" >
                    <Form.Label>{data.inputs[1].label}</Form.Label>
                    <div className="row" style={{ marginTop: '13px', marginLeft: '1px' }}>
                        <TextField id="standard-basic" label="" focused={wizardStateData.steps[stepIndex].selectedDropdownIndex === 1} placeholder={data.inputs[1].placeholder} value={wizardStateData.steps[stepIndex].existingDomain} style={showErrorMessage2 ? { flexGrow: 1, borderColor: '#dc3545' } : { flexGrow: 1 }} onChange={e => handleExistingDomainChange(e)} />
                        <div style={{ width: 120, marginLeft: '33px' }}></div>
                    </div>

                    {/* Custom Error message */}
                    {showErrorMessage2 && (
                        <ErrorMessage errorMessage={data.inputs[1].error_message} />
                    )}
                </div>
            )
            }
        </Form.Group >
    )

}

export default Step4;




// {
//     data.inputs.map((item, index) => (
//         <Form.Group controlId={"radioButton" + index} className="" style={{ marginBottom: '50px' }} onClick={() => handleDomainTypeIndexChange(index)} >
//             <Form.Check type="radio" checked={index === wizardStateData.steps[stepIndex].selectedIndex} index={index} id={index} label={item.label} />
//             {index === 0 && (
//                 <div className="row align-items-end pl-5" >
//                     <TextField id="standard-basic" disabled={index !== wizardStateData.steps[stepIndex].selectedIndex} label="" focused={index === wizardStateData.steps[stepIndex].selectedIndex} placeholder={item.placeholder} value={wizardStateData.steps[stepIndex].yourDomainValue} className="col-7" style={{ maxWidth: '100%' }} onChange={e => handlePersonalDomainChange(e)} />
//                     <div className="dropdown col-3 pr-5 postfix-domain-dropdown" onClick={() => handleDomainTypeIndexChange(index)}>
//                         <button
//                             className="btn-sm btn-outline-secondary dropdown-toggle"
//                             type="button"
//                             id="dropdownMenuButton"
//                             data-toggle=""
//                             aria-haspopup="true"
//                             aria-expanded="false"
//                             style={{ width: '100%', justifyContent: 'space-between' }}
//                             disabled={index !== wizardStateData.steps[stepIndex].selectedIndex}
//                         >
//                             <span style={{ alignSelf: 'flex-start' }}>
//                                 {item.postfixOptions[wizardStateData.steps[stepIndex].yourDomainPostfixIndex]}
//                             </span>
//                         </button>

//                         <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" >
//                             {item.postfixOptions.map((item, index) => (
//                                 <button id={index} className="dropdown-item btn-link" onClick={() => handlePersonalDomainPostfixChange(index)} type="button">
//                                     <span className="dropdown-text">
//                                         {item}
//                                     </span>
//                                 </button>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//             )}
//             { index === 1 && (
//                 <div className="row pl-5" style={{ paddingTop: 17 }} >
//                     <TextField id="standard-basic" disabled={index !== wizardStateData.steps[stepIndex].selectedIndex} label="" focused={index === wizardStateData.steps[stepIndex].selectedIndex} placeholder={item.placeholder} value={wizardStateData.steps[stepIndex].freeDomainValue} className="col-7" style={{ maxWidth: '100%' }} onChange={e => handleFreeDomainChange(e)} />
//                     <span className="col-3 c-gray fw-100" >{item.postfix}</span>
//                 </div>
//             )}
//         </Form.Group>
//     ))
// }



// New
// <div className="dropdown mt-1" >
//                 <Form.Label className="">{data.inputs[0].label}</Form.Label>
//                 <InputGroup hasValidation>
//                     <Dropdown.Toggle id="dropdown-basic"
//                         className="btn-sm btn-outline-secondary dropdown-toggle"
//                         id="dropdownMenuButton"
//                         data-toggle="dropdown"
//                         // aria-haspopup="true"
//                         // aria-expanded="false"
//                         style={{ width: '100%', justifyContent: 'space-between' }}
//                     >
//                         <span style={{ alignSelf: 'flex-start', color: '#221e54' }}>
//                             {wizardStateData.steps[stepIndex].selectedDropdownIndex === -1 ? (data.inputs[0].placeholder === '' ? 'Избери' : data.inputs[0].placeholder) : data.inputs[0].options[wizardStateData.steps[stepIndex].selectedDropdownIndex]}
//                         </span>
//                     </Dropdown.Toggle>
//                     {/* afafc2 */}

//                     {/* Custom Error message */}
//                     {/* {showDropdown1ErrorMsg && (
//                     <div style={{ color: '#dc3545', width: '100%', marginTop: '0.25rem', fontSize: '80%', fontWeight: 400 }}>
//                         {data?.inputs[0].error_message}
//                     </div>
//                 )} */}
//                     {/* Custom Error message */}
//                     {showErrorMessage1 && (
//                         <div style={{ color: '#dc3545', width: '100%', marginTop: '0.25rem', fontSize: '80%', fontWeight: 400, position: 'absolute', bottom: -20 }}>
//                             {data.inputs[1].error_message}
//                         </div>
//                     )}

//                     <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ width: '100%' }}>
//                         {data.inputs[0].options.map((item, index) => (
//                             <button type="button" id={index} key={index} className="dropdown-item btn-link" onClick={() => handleDropdownChange(index)} >
//                                 <span className="dropdown-text">
//                                     {item}
//                                 </span>
//                             </button>
//                         ))}
//                     </div>
//                 </InputGroup>
//             </div>
