/* eslint-disable */
import logoColored from './../../assets/images/vendor-logo-colored.png';
import Stepper from '../components/wizard/stepper/Stepper';
import WizardFormGenerator from '../components/wizard/WizardFormGenerator';
import React, { useEffect, useState } from 'react';

import WizardIntroAndOutro from '../components/wizard/WizardIntroAndOutro';
import { useDispatch, useSelector } from 'react-redux';
import { getWizardAction, getCategoriesAction,  initializeWizardState,  updateWizardState, SetProperties, getPricingTable } from '../../redux/actions/vendorActions';
import { stateIsLoaded } from '../../services/stateHelpers';
import NavigationButtons from '../components/wizard/NavigationButtons';
import { Link } from 'react-router-dom';


function Wizard(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getWizardAction());
        dispatch(getCategoriesAction());
        // dispatch(getModelsAction());
        dispatch(initializeWizardState());
        dispatch(getPricingTable());
        // dispatch(mockWizardAction());

        dispatch(SetProperties(window.innerWidth, 'desktop'));
    }, []);

    const wizardData = useSelector(state => state.wizard);
    const wizardStateData = useSelector(state => state.wizardState.data);


    const [currentStepIndex, setCurrentStepIndex] = useState(-1);   // REMOVE

    // Buttons
    const handleBackButton = () => {
        const newStepIndex = currentStepIndex - 1;

        updateWizaardState(newStepIndex);
        setCurrentStepIndex(newStepIndex);
    }
    const handleNextButton = () => {
        const newStepIndex = currentStepIndex + 1;

        updateWizaardState(newStepIndex);
        setCurrentStepIndex(newStepIndex);
    }

    // WizardState step updater
    const [fix, setFix] = useState(0);
    const updateWizaardState = (stepIndex) => {
        wizardStateData.currentStepIndex = stepIndex;
        console.log("PP1 + " + stepIndex);
        if (stepIndex > wizardStateData.highestReachedStepIndex)
            wizardStateData.highestReachedStepIndex = stepIndex;


        setCurrentStepIndex(stepIndex);
        updateWizardState(wizardStateData);
        setFix(fix + 1);
    }

    const updateWizardState1 = () => {
        const newWizardStateData = { ...wizardStateData };
        dispatch(updateWizardState(wizardStateData));
    }

    // Media Query
    useEffect(() => {
        window.addEventListener('resize', updateMediaQuery);
        return () => window.removeEventListener('resize', updateMediaQuery);
    }, [])
    let currentDeviceType = useSelector(state => state.properties.data.device);
    const updateMediaQuery = () => {
        dispatch(SetProperties(window.innerWidth, currentDeviceType));
    }

    // Validation
    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //     event.preventDefault();
        //     event.stopPropagation();
        // }

        if (currentStepIndex == 1) {
            if (wizardStateData.steps[currentStepIndex].industryIndex != -1 && wizardStateData.steps[currentStepIndex].subIndustryIndex != -1) {
                handleNextButton();
            } else {
                wizardStateData.steps[currentStepIndex].validated = true;
                dispatch(updateWizardState());
                setFix(fix + 1);
            }
        }

        setValidated(true);
        if (form.checkValidity() && currentStepIndex != 1) {
            handleNextButton();
            setValidated(false);
        }
    };

    // When redirected from CASYS
    useEffect(() => {
        var searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('paymentSuccessful')) {
            const paymentSuccessful = searchParams.get('paymentSuccessful');
            if (paymentSuccessful == 'true')
                updateWizaardState(6);
            else
                updateWizaardState(5);
        }
    }, []);

    if (!stateIsLoaded(wizardData))
        return <div></div>

    return (
        <div className="bg-white" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="container" style={{ maxWidth: '1100px', minHeight: '100vh' }}>
                {/* Logo */}
                <nav className="row" style={{ height: '60px', alignItems: 'center' }} >
                    <div className="w-100 d-flex justify-content-center">
                        <Link to="/">
                            <img width={'100px'} src={logoColored} alt={'img'} />
                        </Link>
                    </div>
                </nav>

                {/* Stepper */}
                <div>
                    <Stepper stepIndex={currentStepIndex} setCurrentStepIndex={setCurrentStepIndex} steps={wizardData.data.steps.map(item => { return item.step_title })} wizardStateData={wizardStateData} />
                </div>

                {/* Wizard inputs and Navigation*/}
                {currentStepIndex >= 0 && currentStepIndex < wizardData.data.steps.length && (
                    <div className="flex-column form-nav-wrapper">
                        {/* <Form onSubmit={handleSubmit}> */}
                        <>
                            {/* Wizard inputs */}
                            <div className='container wizard-wrapper'>
                                <WizardFormGenerator stepIndex={currentStepIndex} data={wizardData.data.steps} wizardStateData={wizardStateData} updateWizardStateStep={updateWizaardState} updateWizardState={updateWizardState1} handleBackButton={handleBackButton} handleNextButton={handleNextButton} totalSteps={wizardData.data.steps.length} />
                            </div>

                            {/* Left and right buttons */}
                            <NavigationButtons currentStepIndex={currentStepIndex} handleBackButton={handleBackButton} handleNextButton={handleNextButton} totalSteps={wizardData.data.steps.length} wizardStateData={wizardStateData} updateWizardState={updateWizaardState} />
                        </>
                        {/* </Form> */}
                    </div>
                )}

                {/* Wizzard intro and outro */}
                {(currentStepIndex === -1 || currentStepIndex === wizardData.data.steps.length) && (
                    <div>
                        <WizardIntroAndOutro stepIndex={currentStepIndex} wizardData={wizardData.data} handleNextButton={handleNextButton} handleBackButton={handleBackButton} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Wizard;


