import React from "react";
import Lottie from "lottie-react";
import banner from "../../assets/banners/rasturi-so-onlajn-prodazbi-300x250.json";

function Banners() {
  return (
    <>
      <a href="https://vendor.com.mk">
        <Lottie
          style={{ maxWidth: "100%", maxHeight: "auto" }}
          animationData={banner}
        />
      </a>
    </>
  );
}

export default Banners;
