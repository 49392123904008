/* eslint-disable */
import * as React from 'react';
import viewIcon from "../assets/icons/view.svg";
import hideIcon from "../assets/icons/hide.svg";
import {TextField} from "mui-rff";
import {InputAdornment, makeStyles} from '@material-ui/core';

export default function PasswordInput({name, InputProps, className, ...rest }) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);

  const togglePassword = React.useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const showIcon = showPassword ? viewIcon : hideIcon;
  const type = showPassword ? 'text' : 'password';

  return (
    <TextField
      {...rest}
      name={name}
      className={className}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="start" onClick={togglePassword} className={classes.icon}>
            <img src={showIcon}/>
          </InputAdornment>
        ),
        type,
      }}
    />
  )
}


const useStyles = makeStyles({
  icon: {
    cursor: 'pointer',
  },
});
