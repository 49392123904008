/* eslint-disable */
import React, { useMemo, useState, useEffect } from "react";
import { Form } from "react-final-form";
import _includes from "lodash/includes";
import _isEqual from "lodash/isEqual";
import _capitalize from "lodash/capitalize";
import { makeValidate, TextField } from "mui-rff";
import * as Yup from "yup";
import { Box, makeStyles, Tooltip } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import PhoneInput from "../../../form/PhoneInput.js";
import { authRegister } from "../../../api/vendor-core/v1";
import { useTranslation } from "react-i18next";
import { BasicCheckboxes } from "../../../form/Checkbox.js";
import { useCommonValidation } from "../../../form/validation.js";
import PasswordInput from "../../../form/PasswordInput.js";
import { ManageCookiesContext } from "../../../contexts/ManageCookiesContext.jsx";
import { VariousPropContext } from "../../../contexts/VariousPropContext";
import { LocaleContext } from "../../../App.js";
import { useWindowSize } from "../../../services/UseWindowSize";
import ClickableToolTip from "../../../services/ClickableTooltip";
import { EmailInput } from "../../../form/EmailInput";
import { FormIcons } from "../register/FormIcons2.js";
import axios from "axios";

function trimDashesFromString(phoneNumber) {
  const phoneNumbers = phoneNumber.split("-");
  if (phoneNumbers.length > 1) {
    return `${phoneNumbers[0]}${phoneNumbers[1]}${phoneNumbers[2]}`;
  }
  return phoneNumbers[0];
}
function generateStrongPassword() {
  const letters = "abcdefghijklmnopqrstuvwxyz";
  const capitals = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const symbols = "!@#$%^&*()-_+=";
  const numbers = "0123456789";

  let password = "";

  // Generate random lowercase letters
  for (let i = 0; i < 7; i++) {
    const randomIndex = Math.floor(Math.random() * letters.length);
    password += letters[randomIndex];
  }

  // Add a random capital letter
  const randomCapitalIndex = Math.floor(Math.random() * capitals.length);
  password += capitals[randomCapitalIndex];

  // Add a random symbol
  const randomSymbolIndex = Math.floor(Math.random() * symbols.length);
  password += symbols[randomSymbolIndex];

  // Add a random number
  const randomNumberIndex = Math.floor(Math.random() * numbers.length);
  password += numbers[randomNumberIndex];

  // Shuffle the password characters
  password = shuffleString(password);

  return password;
}
// Helper function to shuffle a string
function shuffleString(string) {
  let array = string.split("");
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array.join("");
}

function Timeline({ background, text, title, timeline }) {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const { cookiesData } = React.useContext(ManageCookiesContext);
  const { plan, subscription_period } = React.useContext(VariousPropContext);
  const { locale } = React.useContext(LocaleContext);
  const cookies = cookiesData;
  const { statistics_cookie } = cookies;
  const isBusiness = true;
  const windowSize = useWindowSize();
  const onRegularRegister = async (values) => {
    const {
      first_name,
      last_name,
      email,
      phonePrefix,
      phoneNumber,
      password,
      presale,
      termsOfServiceMarketing,
    } = values;
    console.log(values, "values");

    const phoneNumberWithoutDashes = trimDashesFromString(phoneNumber);
    const mobile_phone = `${phonePrefix}${phoneNumberWithoutDashes}`;
    const consents = {
      direct_marketing:
        termsOfServiceMarketing && termsOfServiceMarketing.length !== 0,
      terms_of_service: true,
      privacy_policy: true,
    };
    const callback_url = process.env.REACT_APP_VENDOR_MK;
    const want_to_sell_online =
      values.wants_to_sell_online.length !== 0 &&
      values.wants_to_sell_online[0];
    const data = {
      first_name: _capitalize(first_name),
      last_name: _capitalize(last_name),
      mobile_phone,
      email,
      site_id: "15e76909-5a75-41a5-b1a9-e82cb1283b09",
      password: generateStrongPassword(),
      cookies,
      presale: { ...presale, want_to_sell_online, plan, subscription_period },
      consents,
      locale: statistics_cookie ? locale : "mk",
      callback_url,
    };

    const dataToSendInBlue = JSON.stringify({
      sender: {
        name: "Vendor®",
        email: "info@vendor.com.mk",
      },
      to: [
        {
          email: email,
        },
      ],
      templateId: 147,
      params: {
        first_name: first_name,
      },
    });

    const dataToSendInBlueAdmin = JSON.stringify({
      sender: {
        name: "Vendor®",
        email: "info@vendor.com.mk",
      },
      to: [
        {
          email: "dejan@vendor.com.mk",
        },
        {
          email: "ivana.v@vendor.com.mk",
        },
        {
          email: "boban@vendor.com.mk",
        },
      ],
      templateId: 153,
      params: {
        first_name: first_name,
        last_name: last_name,
        email_used: email,
        phone_number: mobile_phone,
      },
    });

    try {
      setServerError(null);
      setIsLoading(true);

      const sendInBlueResponse = await axios.post(
        "https://api.sendinblue.com/v3/smtp/email",
        dataToSendInBlue,
        {
          headers: {
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );

      const sendInBlueResponseAdmin = await axios.post(
        "https://api.sendinblue.com/v3/smtp/email",
        dataToSendInBlueAdmin,
        {
          headers: {
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );

      if (
        sendInBlueResponse.data.messageId &&
        sendInBlueResponseAdmin.data.messageId
      ) {
        const successPath = isBusiness
          ? "/successful-register-business"
          : "/successful-register";

        push(successPath);
      }
    } catch (e) {
      const detail = e?.response?.obj?.detail;
      if (detail) {
        return {
          email: t(`vendorCore.validation.${detail}`),
        };
      } else {
        setServerError(t("register.registrationFailed"));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const { alphanumeric, email, strongPassword } = useCommonValidation();

  const fields = [
    {
      name: "first_name",
      autoComplete: "given-name",
      component: TextField,
      placeholder: t("register.name"),
    },
    {
      name: "last_name",
      autoComplete: "family-name",
      component: TextField,
      placeholder: t("register.lastName"),
    },
    {
      name: "email",
      autoComplete: "email",
      component: EmailInput,
      placeholder: t("register.email"),
    },
    {
      name: "phone",
      autoComplete: "tel-national",
      component: PhoneInput,
      placeholder: t("register.phone"),
      InputProps: { inputMode: "numeric" },
      // fieldProps: {
      //   parse: phoneParser,
      // }
    },
    // {
    //   name: "password",
    //   component: PasswordInput,
    //   autoComplete: "new-password",
    //   placeholder: t("register.password"),
    //   InputProps: { type: "password" },
    // },
  ];
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const displaySecondVendorCondition = true;

  const schema = useMemo(() => {
    return Yup.object().shape({
      first_name: alphanumeric.required(t("register.validation.name")),
      last_name: alphanumeric.required(t("register.validation.lastName")),
      email: email.required(t("register.validation.email")),
      phoneNumber: Yup.string().required(t("register.validation.phone")),
      // password: strongPassword.required(t("register.validation.password")),
      termsOfService: Yup.array()
        .required(t("register.validation.termsOfService"))
        .min(1, t("register.validation.termsOfService")),
    });
  }, []);
  const validate = makeValidate(schema);

  const formInitialValues = {
    phonePrefix: "389",
    termsOfService: [true],
    termsOfServiceMarketing: [true],
    wants_to_sell_online: [isBusiness],
  };
  return (
    <div
      style={{ backgroundImage: `url("/vendor-bg.jpg")` }}
      // style={{
      //   // background: rgb(0, 150, 215),
      //   background: `linear-gradient(135deg, rgba(0,150,215,1) 0%, rgba(77,223,255,1) 100%)`,
      // }}
      className="bg-pale-blue my-3 pb-md-0 pb-3"
      id="timeline-wrapper"
    >
      <div className="container  py-0 py-3">
        {/* <div className="row mb-5 pb-4">
          <div className="col-md-12 mt-md-5 mt-5 mb-md-5 mb-0">
            <h2 className="ff-literal fs-36 text-center c-white lh-45 text-center">
              {title}
            </h2>
            <h6 className="how-to-steps-description py-2 c-white text-center">
              {text}
            </h6>
          </div>

          {timeline.map((step, i) => {
            return (
              <div
                key={i + 2}
                className="col-lg-3 col-md-6 col-sm-6 col-xs-12 single-step-wrapper"
              >
                <div className="steps-text-wrapper">
                  <div className="steps-number-heading-wrap">
                    <div className="text-center timeline-circle"></div>
                    <h5 className="timeline-heading c-white">{step.date}</h5>
                    <h6 className="how-to-steps-heading c-white">
                      {step.title}
                    </h6>
                  </div>
                  <p className="how-to-steps-description c-white">
                    {step.text}
                  </p>
                </div>
              </div>
            );
          })}
        </div> */}

        {/* from here */}

        <Form
          validate={validate}
          initialValuesEqual={_isEqual}
          initialValues={formInitialValues}
          onSubmit={onRegularRegister}
          render={({ handleSubmit, values }) => {
            return (
              <div className="row col-lg-12 mx-auto px-md-2 px-0 align-items-center">
                <div className="col-md-7 px-2  py-0">
                  {/* <h5 className="c-white">
                    Со EarlyBird регистрацијата добиваш <br />
                  </h5> */}
                  {/* <h3 className="c-light-blue d-md-inline-block">
                    1 месец бесплатен период
                  </h3> */}
                  {/* <h3 className="c-white">
                    Креирај ја твојата нова е‑продавница
                    <br />
                    без кодирање и без чекање.
                    <br />
                  </h3> */}

                  <div className="col-md-12 px-md-2 px-0 mt-5">
                    {/* {isBusiness && <FormIcons business={isBusiness} />} */}
                    <img
                      src="/vendor-spring.png"
                      className="img-fluid"
                      // style={{ position: "relative", left: "-35px" }}
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div
                    className="row pb-3"
                    style={{ borderRadius: 16, background: "white" }}
                  >
                    <div
                      className="mx-auto registration-square-wrapper col-xl-9 col-lg-9 col-md-10 col-11 px-md-4 mb-5"
                      style={{ paddingRight: "4px" }}
                    >
                      <div className="register-cta col-md-12 text-center mt-md-5 mt-0">
                        <img
                          src="/semicircular-up-arrow.svg"
                          className="cta-arrow"
                        />
                        <h4 className="c-indigo pt-2 text-left">
                          {/* {t("register.registerNow")} */}
                          Регистрирај се веднаш!
                        </h4>
                        <h6 className="c-indigo pt-2 text-left">
                          {/* {t("register.registerNow")} */}И креирај ја
                          твојата нова е&#8209;продавница.
                        </h6>
                      </div>
                      <form
                        className="col-md-12 px-md-2 px-0"
                        onSubmit={handleSubmit}
                      >
                        <Box
                          display="flex"
                          style={{ flexFlow: "column", marginTop: "20px" }}
                        >
                          {fields.map((field) => {
                            return (
                              <field.component
                                key={field.name}
                                name={field.name}
                                className={`${classes.input} registration-input`}
                                placeholder={field.placeholder}
                                InputProps={{
                                  autoComplete: field.autoComplete,
                                  ...field.InputProps,
                                }}
                                fieldProps={field.fieldProps}
                              />
                            );
                          })}
                          {/* {isBusiness && (
                            <TextField
                              name="presale.store_name"
                              placeholder={t("register.storeName")}
                              autoComplete="organization"
                              className={`${classes.input} registration-input`}
                            />
                          )} */}
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className={`${classes.checkbox}`}
                          >
                            <div className="checkboxes">
                              {" "}
                              <BasicCheckboxes
                                data={[
                                  {
                                    label: t("register.termsOfService"),
                                    value: true,
                                  },
                                ]}
                                name="termsOfService"
                                className={`${classes.termsAndConditions}`}
                                disabled
                              />
                            </div>
                            {windowSize?.width <= 576 ? (
                              <ClickableToolTip
                                title={t("register.termsOfServiceTooltip")}
                              >
                                {" "}
                                <img src="info.svg" width="15px" />
                              </ClickableToolTip>
                            ) : (
                              <Tooltip
                                title={t("register.termsOfServiceTooltip")}
                              >
                                <img src="info.svg" width="15px" />
                              </Tooltip>
                            )}
                          </Box>
                          {displaySecondVendorCondition && (
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              className={`${classes.checkbox}`}
                            >
                              <div className="checkboxes">
                                <BasicCheckboxes
                                  data={[
                                    {
                                      label: t("register.termsOfService2"),
                                      value: true,
                                    },
                                  ]}
                                  name="termsOfServiceMarketing"
                                  className={`${classes.termsAndConditions}`}
                                />
                              </div>
                              {windowSize?.width <= 576 ? (
                                <ClickableToolTip
                                  title={t("register.termsOfServiceTooltip2")}
                                >
                                  {" "}
                                  <img src="info.svg" width="15px" />
                                </ClickableToolTip>
                              ) : (
                                <Tooltip
                                  title={t("register.termsOfServiceTooltip2")}
                                >
                                  <img src="info.svg" width="15px" />
                                </Tooltip>
                              )}
                            </Box>
                          )}
                          {serverError && (
                            <Alert severity="error" color="error">
                              <p>{serverError}</p>
                            </Alert>
                          )}
                          <div className="w-100 text-center">
                            <button
                              id="register-business-button"
                              onClick={handleSubmit}
                              type="submit"
                              disabled={isLoading}
                              className="pink-button fs-18 mt-2 text-center w-100"
                            >
                              {t("register.submitButton")}
                            </button>
                          </div>
                          <div className="" style={{ marginTop: "10px" }}>
                            <span className="small text-muted"></span>
                          </div>
                        </Box>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  input: {
    marginTop: "0.5rem !important",
    minHeight: "40px",
    fontWeight: "100",
    fontSize: "16px !important",
    "&::placeholder": {
      fontSize: "10px",
    },
  },
  checkbox: {
    marginTop: "5px !important",
    minHeight: "30px",
    fontWeight: "100",
    fonySize: "12px",
  },
  termsAndConditions: {
    marginTop: "5px !important",
    fontSize: "6px !important",
    fontWeight: "100 !important",
  },
});

export default Timeline;
