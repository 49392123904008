/* eslint-disable */
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

import heroBanner from "./../../assets/images/vendor-hero-2.png";
import robotAssistant from "./../../assets/images/robot.svg";
import robotWizardFlow from "./../../assets/images/robot-wizard.svg";
import { Helmet } from "react-helmet";

import Header from "../components/landing/Header";
import Logos from "../components/landing/logos";
import Footer from "../components/landing/footer";
import Section6 from "../components/landing/section_6";

import Lottie from "lottie-react";
import thumbsUpRobot from "./../../assets/animations/wave.json";

import axios from "axios";
import { stateIsLoaded } from "../../services/stateHelpers";
import { useDispatch, useSelector } from "react-redux";
import { data } from "jquery";
import { useHistory, useParams } from "react-router-dom";
import { GET_SINGLE_INSIGHT_API } from "../../services/api";
import { browserHistory } from "react-router";
import * as qs from "query-string";
import { useTranslation } from "react-i18next";
import { Wave1, Wave2, Wave3 } from "../components/landing/animated_text";

function Insights(props) {
  const { push } = useHistory();
  let { params } = useParams();

  const { t } = useTranslation();
  const [landingData, setLandingData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [insightData, setInsightData] = useState();
  const [isInsightShown, setIsInsightShown] = useState(false);
  const [shownInsightId, setShownInsightId] = useState(0);

  // const insightClicked = (id) => {
  //   setIsInsightShown(true);
  //   setShownInsightId(id);
  //   fetchInsight(id);
  //   push('/insights?insight=' + id)
  // }

  const fetchInsight = async (id) => {
    setIsLoading(true);
    const result = await axios(GET_SINGLE_INSIGHT_API + "/" + id);
    setInsightData(result.data);
    setIsLoading(false);
  };

  const handleBackClick = () => {
    console.log("BACK " + isInsightShown);
    if (isInsightShown) setIsInsightShown(false);
  };

  const { listen } = useHistory();
  useEffect(() => {
    const unlisten = listen((location) => {
      handleBackClick();
    });

    // remember, hooks that add listeners
    // should have cleanup to remove them
    return unlisten;
  }, []);

  const dispatch = useDispatch();

  return (
    <div>
      <Helmet titleTemplate="Vendor - %s">
        <meta charSet="utf-8" />
        <title>Што е Vendor?</title>
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta name="author" content="Vendor" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />

        <meta property="og:title" content="Vendor - Rethink selling" />
        <meta
          property="og:description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta
          property="og:image"
          content="https://vendor.com.mk/facebook-thumbnail.jpg"
        />
        <meta property="og:url" content="https://vendor.com.mk/" />

        <meta name="twitter:title" content="Vendor - Rethink selling" />
        <meta
          name="twitter:description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta
          name="twitter:image"
          content="https://vendor.com.mk/twitter-thumbnail.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div>
        <Header></Header>

        <div
          className="container-fluid d-flex align-items-center mx-0 px-0"
          id="about-vendor-wrapper"
        >
          <img src="/shape-blue-vertical.svg" class="shape-blue-vertical" />
          <img src="/shape-pink-vertical.svg" class="shape-pink-vertical" />
          <div class="container px-0 px-md-2">
            <div className="row mx-0 my-4 py-4 align-items-center">
              <div className="col-md-10  mx-auto ">
                <h3 className=" fs-18  text-uppercase lh-20 letter-spacing-2 pb-2 c-magenta text-left text-md-center">
                  Што е Vendor?
                </h3>
                <h1 className="fs-36 lh-30 pb-0 c-indigo text-left text-md-center vendor-main-desc">
                  Првата автоматизирана платформа <br /> за е-трговија во
                  Македонија
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container my-md-4 py-md-4 mt-0 pt-0 px-md-2 px-0">
          <div
            className="row mx-0 my-4 py-4 align-items-center"
            id="multipass-wrapper"
          >
            <div className="col-md-6 text-center">
              <img src="/multipass.jpg" className="img-fluid" />
            </div>
            <div className="col-md-6 ">
              <img
                src="/vendor-multipass-logo.svg"
                className="img-fluid mb-2"
              />
              <h2 className="ff-literal fs-36 lh-42 mb-2 c-indigo d-none">
                {parse(t("about.multipass.multipass"))}
              </h2>
              <p className="mb-3 fs-18">
                {parse(t("about.multipass.multipassDesc1"))}
                <br />
                {parse(t("about.multipass.multipassDesc2"))}
              </p>

              {/* <div className="styledList">
                 <div class="styledListItem mb-3">
                  <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                    <img
                      src="/circle.svg"
                      width="20px"
                      className="circle mr-1"
                    />
                    <h4 className="c-indigo fs-20">
                      {parse(t('about.multipass.registrationHeader'))}
                    </h4>
                  </div>
                  <div className="listParagraph">
                    <p>{parse(t('about.multipass.registrationContent'))}</p>
                  </div>
                </div>
                <div class="styledListItem mb-3">
                  <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                    <img
                      src="/circle.svg"
                      width="20px"
                      className="circle mr-1"
                    />
                    <h4 className="c-indigo fs-20">
                      {parse(t('about.multipass.controlHeader'))}
                    </h4>
                  </div>
                  <div className="listParagraph">
                    <p>{parse(t('about.multipass.controlContent'))}</p>
                  </div>
                </div>
                <div class="styledListItem mb-3">
                  <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                    <img
                      src="/circle.svg"
                      width="20px"
                      className="circle mr-1"
                    />
                    <h4 className="c-indigo fs-20">
                      {parse(t('about.multipass.usageHeader'))}
                    </h4>
                  </div>
                  <div className="listParagraph">
                    <p>{parse(t('about.multipass.usageContent'))}</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div
          className="container-fluid pb-5 mx-0 px-0"
          style={{ backgroundColor: "#F5F6FA" }}
        >
          <div className="container mb-5 px-md-2 px-0">
            <div
              className="row mx-0 my-2 align-items-center"
              id="multipass-wrapper"
            >
              <div className="col-md-10 text-center">
                <img src="/smartdesign.jpg" className="img-fluid" />
              </div>
              <div className="col-md-10">
                <h3 className="mb-4 about-section-tagline">
                  {parse(t("about.smartdesign.header"))}
                </h3>
                {/* <p className="mb-3 fs-18">{parse(t('about.smartdesign.content'))}</p> */}
              </div>
              <div class="col-md-12 mx-auto">
                <div className="styledList">
                  <div class="styledListItem mb-3">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="fs-20 c-indigo">
                        {parse(t("about.smartdesign.point1"))}
                      </h4>
                    </div>
                  </div>
                  <div class="styledListItem mb-3">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="fs-20 c-indigo">
                        {parse(t("about.smartdesign.point2"))}
                      </h4>
                    </div>
                  </div>
                  <div class="styledListItem mb-3">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="fs-20 c-indigo">
                        {parse(t("about.smartdesign.point3"))}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container px-md-2 px-0">
          <div
            className="row mx-0 my-4 py-4 align-items-center"
            id="multipass-wrapper"
          >
            <div className="col-md-6">
              <img
                src="/vendor-erp-logo.svg"
                alt="Logo of Vendor ERP"
                className="img-fluid"
              />
              <p className="fs-18 mt-0 mb-3">
                {parse(t("about.vendorErp.content"))}
              </p>
              <div className="styledList">
                <div class="styledListItem mb-3">
                  <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                    <img
                      src="/circle.svg"
                      width="20px"
                      className="circle mr-1"
                    />
                    <h4 className="c-indigo fs-20">
                      {parse(t("about.vendorErp.point1h"))}
                    </h4>
                  </div>
                  {/* <div className="listParagraph">
                    <p>{parse(t('about.vendorErp.point1c'))}</p>
                  </div> */}
                </div>
                <div class="styledListItem mb-3">
                  <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                    <img
                      src="/circle.svg"
                      width="20px"
                      className="circle mr-1"
                    />
                    <h4 className="c-indigo fs-20">
                      {parse(t("about.vendorErp.point2h"))}
                    </h4>
                  </div>
                  {/* <div className="listParagraph">
                    <p>{parse(t('about.vendorErp.point2c'))}</p>
                  </div> */}
                </div>
                <div className="styledListItem mb-3">
                  <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                    <img
                      src="/circle.svg"
                      width="20px"
                      className="circle mr-1"
                    />
                    <h4 className="c-indigo fs-20">
                      {parse(t("about.vendorErp.point3h"))}
                    </h4>
                  </div>
                  {/* <div className="listParagraph">
                    <p>{parse(t('about.vendorErp.point3c'))}</p>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <img src="/vendorapi.jpg" className="img-fluid" />
            </div>
          </div>
        </div>

        <div className="container my-4 py-4 px-md-2 px-0">
          <div className="row mx-0  align-items-center" id="corporate-wrapper">
            <div className="col-md-6 text-center order-md-1 order-2">
              <video
                style={{ width: "100%", margin: "0px" }}
                autostart="true"
                autoPlay
                loop
                muted
                type="video/mp4"
                poster="/vendor-corporate.jpg"
                src="/vendor-corporate.mp4"
              ></video>
            </div>
            <div className="col-md-5 h-100 order-md-2 order-1">
              <img
                src="/vendor-corporate-logo.svg"
                alt="Logo of Vendor ERP"
                className="img-fluid"
              />
              <p className="fs-18 mt-2">
                {parse(t("about.vendorCorporate.content"))}
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid bg-dark-blue py-5 mx-0 px-0">
          <div className="container my-4">
            <div className="row mx-0 my-4 py-4 align-items-center">
              <div className="col-md-6">
                <div class="col-md-8 px-0">
                  <img
                    src="/virtual-assistant-logo.svg"
                    className="img-fluid"
                  />
                </div>
                <p className="c-lavander mt-3 fs-18 ">
                  {parse(t("about.virtualAssistant.content"))}
                </p>
                <div className="styledList mt-4">
                  <div class="styledListItem mb-5">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="fs-20 c-white">
                        {parse(t("about.virtualAssistant.point1h"))}
                      </h4>
                    </div>
                    <div className="listParagraph">
                      <p className="c-lavander">
                        {parse(t("about.virtualAssistant.point1c"))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-center robot-wrap">
                <Lottie className="" animationData={thumbsUpRobot} />
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid pt-5 mt-5 mx-0 px-0"
          style={{
            backgroundImage: `url("/vendor-map.svg")`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="container ">
            <div
              className="row mx-0 my-4 py-4 align-items-center mx-0 px-0"
              id="multipass-wrapper"
            >
              <div className="col-md-12 mx-auto text-center">
                <div class="col-md-6 mx-auto px-0">
                  <img
                    src="/vendorclub.svg"
                    alt="Logo of Vendor Club"
                    className="img-fluid"
                  />
                  <p className="fs-18 lh-25 mt-3 mb-3">
                    {parse(t("about.vendorClub.content"))}
                  </p>
                </div>
                <div className="styledList col-md-12 row text-left mt-5 px-0">
                  <div class="styledListItem mb-5 col-md-4">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="c-indigo fs-20">
                        {parse(t("about.vendorClub.point1h"))}
                      </h4>
                    </div>
                    <div className="listParagraph">
                      <p>{parse(t("about.vendorClub.point1c"))}</p>
                    </div>
                  </div>
                  <div className="styledListItem mb-5 col-md-4">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="c-indigo fs-20">
                        {parse(t("about.vendorClub.point2h"))}
                      </h4>
                    </div>
                    <div className="listParagraph">
                      <p>{parse(t("about.vendorClub.point2c"))}</p>
                    </div>
                  </div>
                  <div className="styledListItem mb-5 col-md-4">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="c-indigo fs-20">
                        {parse(t("about.vendorClub.point3h"))}
                      </h4>
                    </div>
                    <div className="listParagraph">
                      <p>{parse(t("about.vendorClub.point3c"))}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mb-40">
                <h1 className="ff-literal fs-36 text-center c-dark-blue lh-45 disable-br-mobile">
                  {parse(t("about.vendorClub.becomeMember"))}
                </h1>
              </div>
              <div className="col-md-12 text-center">
                <a
                  className="pink-button"
                  id="about-register-button"
                  href="/register-business"
                >
                  {parse(t("about.vendorClub.register"))}
                </a>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    </div>
  );
}

export default Insights;
