import { useState, useRef } from 'react';
import { Tooltip } from '@material-ui/core';
import { useOutsideClicker } from '../services/useOutsideClicker';


const ClickableTooltip = ({ placement,title, classname, children }) => {
  const [open, setOpen] = useState(false);
   const myRef = useRef()
   useOutsideClicker(myRef, setOpen)
  return (
    <Tooltip
    ref={myRef}
      placement={(placement)? placement : 'bottom'}
      title={title}
      className={classname || ""}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      onClick={() => setOpen(!open)}
    >
     <span>{children}</ span>
    </Tooltip>
  );
};

export default ClickableTooltip;
