/* eslint-disable */
import React from 'react';
import { DropdownButton, Form, InputGroup, Dropdown } from "react-bootstrap";

function CustomDropdown({ onClick, value, items, label, showError = false, disabled = false, showItems = true }) {
    return (
        <Form.Group>
            <Form.Label>
                {label}
            </Form.Label>
            <div className="dropdown mt-1" >
                <InputGroup hasValidation>
                    <Dropdown.Toggle
                        className="btn-sm btn-outline-secondary dropdown-toggle"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        style={showError ? { width: '100%', justifyContent: 'space-between', borderColor: '#dc3545' } : { width: '100%', justifyContent: 'space-between' }}
                    >
                        <span style={{ alignSelf: 'flex-start', color: disabled === -1 ? '#afafc2' : '#221e54' }}>
                            {value}
                        </span>
                    </Dropdown.Toggle>

                    <div id="custom-menu" className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ width: '100%' }}>
                        {showItems && items.map((item, index) => (
                            <button type="button" id={index} key={index} className="dropdown-item btn-link" onClick={() => onClick(index)} >
                                <span className="dropdown-text">
                                    {item.title}
                                </span>
                            </button>
                        ))}
                    </div>
                </InputGroup>
            </div>

        </Form.Group>
    )
}

export default CustomDropdown;

