import * as React from "react";

import { TextField } from 'mui-rff';
import {useForm, useFormState} from "react-final-form";
import _get from "lodash/get";
import _isNil from "lodash/isNil";

const isEmailInvalid = (value) => {
    const emailPattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return !emailPattern.test(value);
}

export const EmailInput = ({name, InputProps, className, ...rest }) => {
    const [invalidEmail, setInvalidEmail] = React.useState(false);
    const formApi = useForm();
    const { errors, submitErrors } = useFormState();

    const currentFieldFormError = _get(errors,name)
    const submitError = _get(submitErrors, name);

    const onChange = (e) => {
        formApi.change(name, e.target.value);
        if(!isEmailInvalid(e.target.value)){
            setInvalidEmail(false);
        }
    }

    const validateEmail = (e) => {
        const email = e.target.value;
        if (!email || isEmailInvalid(email)) {
            setInvalidEmail(true);
        } else {

            setInvalidEmail(false);
        }
    }


        return (
            <TextField
                {...rest}
                name={name}
                id="email-address"
                className={className}
                onChange={onChange}
                onBlur={validateEmail}
                error={
                    invalidEmail ||
                    (!_isNil(currentFieldFormError)) ||
                    submitError
                }
                helperText={invalidEmail ? 'Невалиден е-маил' :''}
                InputProps={InputProps}
            />
        );
}