import { ActionStatus } from '../../core/ActionStatus';
import { buildActionType } from '../../actions/utils/buildActionType';
import { StateStatus } from '../../core/StateStatus';

const initialState = {
    data: {},
    status: StateStatus.NOT_INITIALIZED,
};

const basicReducer = actionType => (state = initialState, action) => {
    switch (action.type) {
        case buildActionType(actionType, ActionStatus.START):
            return {
                ...state,
                status: StateStatus.LOADING,
                version: state.version,
                entity: actionType.entity,
            };

        case buildActionType(actionType, ActionStatus.DONE):
            return {
                status: StateStatus.LOADED,
                data: action.payload,
                entity: actionType.entity,
                version: +new Date(),
            };

        case buildActionType(actionType, ActionStatus.FAILED):
            return {
                status: StateStatus.ERROR,
                errors: action.payload.errors,
            };

        case buildActionType(actionType, ActionStatus.FAILED_BACKUP):
            return {
                status: StateStatus.ERROR,
                data: action.payload.data,
                errors: action.payload.errors,
            };

        case buildActionType(actionType, ActionStatus.RESET):
            return initialState;

        default:
            return state;
    }
};

export { basicReducer };
