import React from 'react';
import { Form, InputGroup } from "react-bootstrap";

function TextInput({ label, placeholder, value, onChange, required, index, showError }) {
    return (
        <InputGroup hasValidation className="mb-4" >
            <Form.Label >{label}</Form.Label>
            <Form.Control style={showError ? { borderColor: '#dc3545', width: '100%' } : { width: '100%' }} type={'text'} placeholder={placeholder} value={value} onChange={(event) => { onChange(event.target.value, index) }} required={required} className="dropdown-custom" />
        </InputGroup>
    )
}

export default TextInput;