/* eslint-disable */
import React, { useState } from 'react';

import checkIcon from '../../../../../assets/icons/check.png';
import ShopImage from '../../../../../assets/icons/shop.png';
import CancelImage from '../../../../../assets/icons/cancel.png';
import Modal from 'react-bootstrap/Modal'


import IMG1 from '../../../../../assets/icons/payment-icon-card.svg';
import IMG2 from '../../../../../assets/icons/payment-icon-invoice.svg';

import DesktopIcon from '../../../../../assets/icons/models/desktop-selected.svg';
import TabletIcon from '../../../../../assets/icons/models/tablet-selected.svg';
import PhoneIcon from '../../../../../assets/icons/models/mobile-selected.svg';
import DesktopNotselectedIcon from '../../../../../assets/icons/models/desktop-notselected.svg';
import TabletNotselectedIcon from '../../../../../assets/icons/models/tablet-notselected.svg';
import PhoneNotselectedIcon from '../../../../../assets/icons/models/mobile-notselected.svg';

function ShopStyleCard({ data, selected, paddingOnRightSide, cardIndex, handleModelChange }) {

    let styles = {
        wrapper: {
            // paddingRight: paddingOnRightSide ? '15px' : '0px',
            // paddingLeft: paddingOnRightSide ? '0px' : '15px',
        },
        notSelectedCard: {
            borderRadius: '10px',
            borderWidth: '1px',
        },
        selectedCard: {
            borderRadius: '10px',
            borderWidth: '1px',
            borderColor: "#eb1e5f",
        },
        image: {
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            height: '200px',
            objectFit: 'cover'
        },
        tick: {
            position: 'absolute',
            right: '-10px',
            top: '-10px',
            width: '20px',
            height: '20px'
        },
        optionsWrapper: {
            position: 'absolute',
            width: '100%',
            height: '200px',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0, 0, 0, 0.5)',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
        },
        option: {
            color: "#FFFFFF",
            borderRadius: '25px',
            borderWidth: 0,
            fontSize: '22px',
            padding: "8px 26px"
        },
        option2: {
            color: "#FFFFFF",
            borderRadius: '25px',
            borderWidth: 0,
            fontSize: '18px',
            padding: "8px 26px"
        },
        bgBlueWrapper: {
            borderWidth: 1,
            borderColor: "#3dabe0",
            borderStyle: "solid",
            color: "#3dabe0",
            borderRadius: '25px',
            fontSize: '22px',
            padding: "8px 26px"
        },
        svgColors: {
            active: '#35a9e1',
            notActive: '#c7e9f9'
        },

    }

    const [optionsAreShown, setOptionsAreShown] = useState(false);
    const [show, setShow] = useState(false);

    const handleSelectionChange = () => {
        handleModelChange(cardIndex);
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleModalOdberi = () => {
        handleModelChange(cardIndex);
        setOptionsAreShown(false);
        handleClose();
    }

    const [imagePreviewDevice, setImagePreviewDevice] = useState('desktop');
    const getPreviewImage = () => {
        if (imagePreviewDevice === 'phone')
            return data.image_mobile;
        else if (imagePreviewDevice === 'tablet')
            return data.image_tablet;
        else
            return data.image_desktop;
    }


    const listMock = ['Галериска организација', 'Табови за поделба на категории', 'Едноставно навигациско мени', 'Споделување со социјални медиуми', 'Е-билтен форма']

    return (
        <div className="mb-4 col-lg-6 col-md-6 col-sm-12"
            style={styles.wrapper}
            onMouseEnter={() => setOptionsAreShown(true)}
            onMouseLeave={() => setOptionsAreShown(false)}
        >
            {/* Model card */}
            <div className="card" style={selected ? styles.selectedCard : styles.notSelectedCard}>
                <img className="card-img-top" src={data.featured_image} alt={"Card cap"} style={styles.image} />
                <div className="card-body" style={{}}>
                    <h5 className="card-title c-indigo">{data.title}</h5>
                </div>

                {optionsAreShown && (
                    <div className="col dizajn-btns-wrapper dizajn-btns-wrapper-desktop" style={styles.optionsWrapper}>
                        <button className="bg-darker-blue" style={styles.option} onClick={handleShow} type="button">Преглед</button>
                        <button className="bg-magenta mt-3" style={styles.option} onClick={handleSelectionChange} type="button">Одбери</button>
                    </div>
                )}
                {/*  data-toggle="modal" data-target="#exampleModalCenter"  */}

                {selected && (
                    <img src={checkIcon} alt={"Tick"} style={styles.tick} />
                )}

                <div className="dizajn-btns-wrapper-mobile" >
                    <button className="bg-white" style={styles.bgBlueWrapper} onClick={handleShow} type="button">Преглед</button>
                    <button className="bg-magenta mt-3" style={styles.option} onClick={handleSelectionChange} type="button">Одбери</button>
                </div>
            </div>

            {/* Preview */}
            <Modal show={show} size="xl" onHide={handleClose} style={{ borderRadius: '20px' }} className="rounded-0">
                <Modal.Body style={{ height: '700px', padding: 0, borderRadius: '20px' }} className="rounded-0">
                    {/* Close button */}
                    <div style={{ position: 'relative', zIndex: 10, }}>
                        <div style={{ position: 'absolute', right: 10, top: 15 }} >
                            <button type="button" className="close position-relative" onClick={handleClose}>
                                <img width={"20px"} src={CancelImage} alt={'Close'} />
                            </button>
                        </div>

                    </div>
                    {/* Body */}
                    <div className="row modal-wrapper" style={{ height: '700px', zIndex: 9, background: 'white', borderRadius: '20px' }}>

                        {/* Body */}
                        <div className="row modal-wrapper" style={{ height: '700px', zIndex: 9, background: 'white', borderRadius: '20px' }}>
                            <div className="col-lg-7 bg-pale-blue modal-cart-img-wrapper" style={{ height: '700px', padding: 0, borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }}>
                                <div className="px-5 py-5 h-100 d-flex justify-content-center align-items-center" >
                                    <div className="d-flex w-100 justify-content-center" style={{ position: 'relative' }}>
                                        {/* Buttons */}
                                        <div style={{ position: 'absolute', top: -70 }} className="row d-flex w-100 justify-content-center">
                                            <img height={38} src={imagePreviewDevice === 'desktop' ? DesktopIcon : DesktopNotselectedIcon} style={{ fill: '#555555' }} alt={'Shoping card'} className="mr-4" onClick={() => { setImagePreviewDevice("desktop") }} />
                                            <img height={38} src={imagePreviewDevice === 'tablet' ? TabletIcon : TabletNotselectedIcon} fill={styles.svgColors.active} alt={'Shoping card'} className="mr-4" onClick={() => { setImagePreviewDevice("tablet") }} />
                                            <img height={38} src={imagePreviewDevice === 'phone' ? PhoneIcon : PhoneNotselectedIcon} alt={'Shoping card'} onClick={() => { setImagePreviewDevice("phone") }} />
                                        </div>
                                        {/* Preview image */}
                                        <img height={300} src={getPreviewImage()} alt={'Shoping card'} />
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-5 pl-4 pr-4 pt-5 modal-cart-text-wrapper" style={{ height: '700px', padding: 0 }}>
                                <div className="mt-5 mb-3 c-indigo fw-900 fs-21">{data.title}</div>
                                <div className="mb-3 fw-100 modal-description">{data.description}</div>
                                {/* Components */}
                                {data.components && (
                                    <>
                                        <div className="c-indigo fw-900 mb-3" style={{ fontSize: '15px' }}>Компоненти:</div>
                                        <ul>
                                            {data.components.map((item, index) => (
                                                <li className="mb-2 fw-100">
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                                <div className="odberi-btn-desktop" style={{ position: 'absolute', right: 60, bottom: 40 }}>
                                    <button className="bg-magenta mt-3" style={styles.option2} onClick={handleModalOdberi} data-dismiss="modal">Одбери</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </div >
    )
}

export default ShopStyleCard;
