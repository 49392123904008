/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import mk from "../../../assets/api/mk/pricing-table.json";
import en from "../../../assets/api/en/pricing-table.json";
import sq from "../../../assets/api/sq/pricing-table.json";
import mk_monthly from "../../../assets/api/mk/pricing-table-mothly.json";
import en_monthly from "../../../assets/api/en/pricing-table-mothly.json";
import sq_monthly from "../../../assets/api/sq/pricing-table-mothly.json";
import { Form } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LocaleContext } from "../../../App.js";
import ClickableTooltip from "../../../services/ClickableTooltip";
import { VariousPropContext } from "../../../contexts/VariousPropContext";
import parse from "html-react-parser";

const pricingTableDataMap = {
  mk,
  en,
  sq,
};

const pricingTableDataMapMonthly = {
  mk: mk_monthly,
  en: en_monthly,
  sq: sq_monthly,
};

function PricingTable() {
  const { t } = useTranslation();
  const { locale } = React.useContext(LocaleContext);
  const [pricingTableData, setPricingTableData] = useState(
    pricingTableDataMap[locale]
  );
  const { setPlan, setSubscription_period } =
    React.useContext(VariousPropContext);
  const [dataType, setDataType] = useState("yearly");
  const [open, setOpen] = React.useState(false);
  console.log(dataType, " datatype");

  useEffect(() => {
    const data =
      dataType === "yearly"
        ? pricingTableDataMap[locale]
        : pricingTableDataMapMonthly[locale];
    setPricingTableData(data);
  }, [dataType, locale]);

  const handleDataTypeChange = (e) => {
    const { id } = e.target;
    setDataType(id);
    setSubscription_period(id);
  };
  const plans = pricingTableData["plans"];

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Form.Group className="mb-0 select-payment-interval-wrapper">
        <div className="d-flex align-items-center flex-column">
          <Form.Check
            type="radio"
            label="Годишно плаќање"
            name="dataType"
            id="yearly"
            onChange={handleDataTypeChange}
            checked={dataType === "yearly"}
            class={"toggle-btn"}
          />
          <div className="discount-text">{plans.save_text}</div>
        </div>
        <div className="d-flex align-items-center flex-column">
          <Form.Check
            type="radio"
            label="Месечно плаќање"
            name="dataType"
            onChange={handleDataTypeChange}
            id="monthly"
            checked={dataType === "monthly"}
            class={"toggle-btn"}
          />
        </div>
      </Form.Group>
      <div class="row mx-0">
        <div class="col-md-4">
          <div class="plan-wrapper py-5 text-center">
            <h5 className="plan-name">{plans.plan1.planName}</h5>
            <p className="plan-text text-center px-3 mt-3">
              {plans.plan1.planDescription}
            </p>
            <h4 className="plan-price">{plans.plan1.planPrice}</h4>
            <h6 className="plan-recurrence c-indigo">
              {plans.plan1.planRecurrence}
            </h6>
            <p className="text-center small text-muted">
              {plans.plan1.provision}
            </p>
            <Link
              to="/register-business"
              id="box-plan-1-register-button"
              className="header-button mx-auto mt-3 d-inline-block text-center"
              onClick={() => setPlan(1)}
            >
              {plans.chosePlan}
            </Link>
          </div>
        </div>

        <div className="col-md-4">
          <div className="plan-wrapper py-5 text-center">
            <h5 className="plan-name">{plans.plan2.planName}</h5>
            <p className="plan-text text-center px-3 mt-3">
              {plans.plan2.planDescription}
            </p>
            <h4 className="plan-price">{plans.plan2.planPrice}</h4>
            <h6 className="plan-recurrence c-indigo">
              {plans.plan2.planRecurrence}
            </h6>
            <p className="text-center small text-muted">
              {plans.plan2.provision}
            </p>
            <Link
              to="/register-business"
              id="box-plan-2-register-button"
              className="header-button mx-auto mt-3 d-inline-block text-center"
              onClick={() => setPlan(1)}
            >
              {plans.chosePlan}
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="plan-wrapper py-5 text-center">
            <h5 className="plan-name">{plans.plan3.planName}</h5>
            <p className="plan-text text-center px-3 mt-3">
              {plans.plan3.planDescription}
            </p>
            <h4 className="plan-price">{plans.plan3.planPrice}</h4>
            <h6 className="plan-recurrence c-indigo">
              {plans.plan3.planRecurrence}
            </h6>
            {/* <a href="#fees-table" data-toggle="modal" data-target="#fees-table"> */}
            <p className="text-center small text-muted">
              {plans.plan3.provision}
            </p>
            {/* </a> */}
            <Link
              to="/register-business"
              id="box-plan-3-register-button"
              className="header-button mx-auto mt-3 d-inline-block text-center"
              onClick={() => setPlan(1)}
            >
              {plans.chosePlan}
            </Link>
          </div>
        </div>
      </div>
      <div
        class="modal fade rounded-0"
        id="fees-table"
        tabindex="-1"
        aria-labelledby="fees-tableLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-scrollable mt-5 pt-5 rounded-0">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title c-indigo" id="fees-tableLabel">
                Табела на провизии за План 3
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <table class="w-100 fees-table">
                <thead>
                  <tr class="table-row-header">
                    <th>Вкупен остварен промет</th>
                    <th>Стапка на провизија</th>
                    <th>Износ на провизија на гранична сума</th>
                  </tr>
                </thead>
                <tr>
                  <td>0 - 599.999 ден.</td>
                  <td>0.9%</td>
                  <td>5.400 ден.</td>
                </tr>
                <tr>
                  <td>600.000 - 999.999 ден.</td>
                  <td>0.8%</td>
                  <td>8.000 ден.</td>
                </tr>
                <tr>
                  <td>600.000 - 999.999 ден.</td>
                  <td>0.8%</td>
                  <td>8.000 ден.</td>
                </tr>
                <tr>
                  <td>1.000.000 - 1.499.999 ден.</td>
                  <td>0.7%</td>
                  <td>10.500 ден.</td>
                </tr>
                <tr>
                  <td>над 1.500.000 ден.</td>
                  <td>фиксен износ</td>
                  <td>10.500 ден.</td>
                </tr>
              </table>
              <div class="my-3">
                <p>
                  За Продажниот план 3 Провизијата се пресметува според прагот
                  во кој се наоѓа вкупниот приход во конкретниот месец, според
                  извештаите на Vendor електронската продавница.
                  <br />
                  <br />
                  Колоната Износ на провизија на гранична сума ја претставува
                  највисоката провизија за конкретниот праг, како на пример:
                  <br />
                  <br />
                  <strong>
                    Во првиот праг е пресметана за 600.000 денари и покажува
                    дека максималната провизија во тој праг не надминува 5400
                    денари.
                  </strong>
                  <br />
                  <br />
                  По надминување од прагот од 1.500.000 денари провизијата
                  поради политика на деловна коректност станува фиксна и е
                  10.500 денари, без разлика на тоа колку повисок промет е
                  направен конкретниот месец.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 text-center my-2 text-muted small">
        {parse(plans.tax_disclaimer)}
      </div>

      <div className="col-md-12 text-center my-5">{parse(plans.content)}</div>
      <div>
        <table className="main-table-plans-wrapper w-100 th-borderless header-plans-wrapper">
          <tr>
            <th class="d-sm-block d-none"></th>
            {Object.values(pricingTableData["titles"]).map(
              (title, title_index) => {
                const { heading, Label_above_heading, Label_below_heading } =
                  title;
                return (
                  <td>
                    <h6 className="mb-0 my-0 c-darker-blue ff-literal">
                      {heading}{" "}
                    </h6>
                  </td>
                );
              }
            )}
          </tr>
        </table>

        {Object.values(pricingTableData["table"]).map(
          (category, category_index) => {
            const { rows, name } = category;
            return (
              <div>
                <div
                  className="accordion-header table-row-header py-2 px-2"
                  data-toggle="collapse"
                  data-target={`#category-${category_index}`}
                >
                  <h5 className="my-1 c-indigo fs-16">
                    {name}
                    <i class="arrow-accordion"></i>
                  </h5>
                </div>
                <div
                  id={`category-${category_index}`}
                  className={"table-wrapper-section collapse show"}
                >
                  <table className="main-table w-100 desktop-table">
                    {rows.map((row) => {
                      return (
                        <tr>
                          {row.map((col, index) => {
                            if (index === 0) {
                              return (
                                <th>
                                  {row[1]}
                                  <Tooltip title={parse(col)} className="ml-2">
                                    <img
                                      src="info.svg"
                                      width="15px"
                                      style={{ opacity: "0.5" }}
                                    />
                                  </Tooltip>
                                </th>
                              );
                            } else if (index > 1) {
                              return (
                                <td>
                                  {col === "checked" ? (
                                    <img src="/check-blue.svg" width="16px" />
                                  ) : col === "not-available" ? (
                                    <Tooltip title="постои можност за дополнително активирање">
                                      <img
                                        src="/question-mark.svg"
                                        width="16px"
                                      />
                                    </Tooltip>
                                  ) : (
                                    col
                                  )}
                                </td>
                              );
                            }
                          })}
                        </tr>
                      );
                    })}
                  </table>

                  {/* Mobile */}
                  <div className="main-table w-100 mobile-table">
                    {rows.map((row) => {
                      return (
                        <div className="row mx-0 table-row">
                          {row.map((col, index) => {
                            if (index === 0) {
                              return (
                                <div className="col-11 px-0 mx-auto">
                                  <th colSpan="3">
                                    <ClickableTooltip
                                      placement="bottom-end"
                                      title={parse(col)}
                                      classname="ml-1 tooltip-mobile"
                                    >
                                      {" "}
                                      <img
                                        src="info.svg"
                                        width="15px"
                                        style={{ opacity: "0.5" }}
                                      />
                                    </ClickableTooltip>
                                    {row[1]}
                                  </th>
                                </div>
                              );
                            } else if (index > 1) {
                              return (
                                <div className="col-4 px-0">
                                  <td>
                                    {col === "checked" ? (
                                      <img src="/check-blue.svg" width="16px" />
                                    ) : col === "not-available" ? (
                                      <ClickableTooltip
                                        placement="bottom"
                                        title="постои можност за дополнително активирање"
                                      >
                                        <img
                                          src="/question-mark.svg"
                                          width="16px"
                                        />
                                      </ClickableTooltip>
                                    ) : (
                                      col
                                    )}
                                  </td>
                                </div>
                              );
                            }
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          }
        )}

        <table className="main-table-plans-wrapper w-100 th-borderless footer-plans-wrapper desktop-table">
          <tr>
            <th className="d-sm-block d-none"></th>
            <td>
              <Link
                to="/register-business"
                id="table-plan-1-register-button"
                className="header-button mx-auto my-1 d-inline-block text-center"
              >
                {plans.chosePlan} 1
              </Link>
            </td>
            <td>
              <Link
                to="/register-business"
                id="table-plan-2-register-button"
                className="header-button mx-auto my-1 d-inline-block text-center"
              >
                {plans.chosePlan} 2
              </Link>
            </td>
            <td>
              <Link
                to="/register-business"
                id="table-plan-3-register-button"
                className="header-button mx-auto my-1 d-inline-block text-center"
              >
                {plans.chosePlan} 3
              </Link>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default PricingTable;
