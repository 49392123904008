/* eslint-disable */
import * as React from 'react';
import { TextField, Select } from 'mui-rff';
import { Box, makeStyles, MenuItem } from '@material-ui/core';
import ReactCountryFlag from 'react-country-flag';
import phoneData from '../assets/jsons/phone.json';
import { formatPhoneNumber} from "./parsers";
import {useForm, useFormState} from "react-final-form";
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _toString from 'lodash/toString';

export default function PhoneInput ({ fieldProps, name, className, ...rest }) {
  const classes = useStyles();
  const [errorMessage,setErrorMessage] = React.useState('');

  const validationMessage = 'Невалиден формат (07X XXX XXX)';
  const validationDigitNumberMessage = 'Бројот треба да има 9 цифри';

  const formApi = useForm();
  const {errors} = useFormState();
  const currentFieldFormError = _get(errors,`${name}Number`)

  const handleChange =(e)=>{
    const numberInput = e.target.value;
    const parsedValue = formatPhoneNumber(numberInput);
    formApi.change(`${name}Number`, parsedValue);

    if(numberInput.length > 1 && _toString(numberInput[0]) ==="0" && _toString(numberInput[1]) !=="7" ){
      setErrorMessage(validationMessage);
    }else if(_toString(numberInput[0]) === '0' || _toString(numberInput[0]) === '7'){
      setErrorMessage('');
    }
    else{
      setErrorMessage(validationMessage);
    }
  };

  const validatePhoneInput = React.useCallback((e)=>{
    const phoneInput = e.target.value;
    const trimmedFromDashes= phoneInput ? phoneInput.replace(/-/g, "") : phoneInput;
      if(errorMessage === '' && trimmedFromDashes.length > 0 && trimmedFromDashes.length < 9){
        setErrorMessage(validationDigitNumberMessage);
      }else{
        setErrorMessage('');
      }

  },[setErrorMessage])

  const [countries, setCountries] = React.useState([]);
  React.useEffect( () => {
    const data = Object.keys(phoneData).map(countryCode => ({
        countryCode,
        phonePrefix: phoneData[countryCode]
      }
    ));
    setCountries(data);
  }, [phoneData]);

  return (
    <Box className={`${classes.container} ${className}`}>
      <TextField
        name={`${name}Number`}
        onBlur={validatePhoneInput}
        {...rest}
        fieldProps={{ ...fieldProps, parse: formatPhoneNumber }}
        className={`${classes.number} ${className}`}
        error={errorMessage !== '' || !_isNil(currentFieldFormError)}
        helperText={errorMessage}
        inputProps={{ inputMode: 'numeric' }}
        onChange={handleChange}
      />
      <Select
        name={`${name}Prefix`}
        className={classes.prefix}
      >
        {countries.map((c => {
          return (
            <MenuItem value={c.phonePrefix} key={c.countryCode}>
              <Box display="flex" alignItems="center">
                <ReactCountryFlag countryCode={c.countryCode} />
                <Box style={{ marginLeft: '5px'}}>{c.phonePrefix}</Box>
              </Box>
            </MenuItem>
          )
        }))}
      </Select>
    </Box>
  );
}

const useStyles = makeStyles({
  container: {
    position: 'relative',
    height: '50px',
  },
  prefix: {
    width: '85px',
    position: 'absolute',
    '&:before': {
      border: '0 !important',
    },
    '&:after': {
      border: '0 !important',
    },
  },
  number: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    '& input': {
      marginLeft: '80px !important',
    },
  },
});
