/* eslint-disable */
// import { Dropdown } from 'bootstrap';
import { FormControl, NativeSelect } from '@material-ui/core';
import React, { useState } from 'react';
import { Form, Dropdown, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ErrorMessage from '../../inputs/ErrorMessage';

import CustomDropdown from '../../inputs/CustomDropdown';
import InputErrorWrapper from '../../inputs/InputErrorWrapper';
import CustomDropdownCheckbox from '../../inputs/CustomDropdownCheckbox';

function Step2({ data, stepIndex, wizardStateData }) {

    const [fix, setFix] = useState('');
    const categoriesData = useSelector(state => state.categories.data);

    const handleIndustryChange = (index) => {
        // wizardStateData.steps[stepIndex].subIndustryIndex = -1;
        wizardStateData.steps[stepIndex].industryIndex = index;
        wizardStateData.steps[stepIndex].industryName = categoriesData.categories[index].title;
        wizardStateData.steps[stepIndex].subIndustryChecked = [];
        wizardStateData.steps[stepIndex].validated = false;

        setFix(fix + index);
    }

    // const handleSubIndustryChange = (index) => {
    //     wizardStateData.steps[stepIndex].subIndustryIndex = index;
    //     wizardStateData.steps[stepIndex].validated = false;
    //     setFix(fix + index);
    // }

    const handleSubIndustryCheckChange = (item) => {
        console.log("^^% " + JSON.stringify(item));
        // if (wizardStateData.steps[stepIndex].subIndustryChecked.includes(item))
        if (wizardStateData.steps[stepIndex].subIndustryChecked.some(i => i.id === item.id))
            wizardStateData.steps[stepIndex].subIndustryChecked = wizardStateData.steps[stepIndex].subIndustryChecked.filter(i => i.id !== item.id)
        else
            wizardStateData.steps[stepIndex].subIndustryChecked.push(item);

        wizardStateData.steps[stepIndex].validated = false;
        setFix(fix + item);
    }

    const showDropdown1ErrorMsg = (!wizardStateData.steps[stepIndex].successfulValidation && wizardStateData.steps[stepIndex].industryIndex == -1)
    const showDropdown2ErrorMsg = (!wizardStateData.steps[stepIndex].successfulValidation && wizardStateData.steps[stepIndex].subIndustryChecked.length == 0)



    return (
        <>
            <Form.Group id="step1" controlId="step1" style={{ alignItems: "flex-start" }} className="mt-4" >
                {/* Company Category Dropdown */}
                <InputErrorWrapper id={"Step2-input0"} className="mb-4" errorMessage={data?.inputs[0].error_message} showError={showDropdown1ErrorMsg}>
                    <CustomDropdown
                        label={data?.inputs[0].label}
                        value={wizardStateData.steps[stepIndex].industryIndex === -1 ? data.inputs[0].placeholder : categoriesData.categories[wizardStateData.steps[stepIndex].industryIndex].title}
                        items={categoriesData.categories}
                        onClick={handleIndustryChange}
                        showError={showDropdown1ErrorMsg}
                    />
                </InputErrorWrapper>

                {/* Company Subcategory Dropdown */}
                <InputErrorWrapper id={"Step2-input1"} className="mb-4" errorMessage={data?.inputs[1].error_message} showError={showDropdown2ErrorMsg}>
                    <CustomDropdownCheckbox
                        label={data?.inputs[1].label}
                        // value={wizardStateData.steps[stepIndex].subIndustryIndex === -1 ? data.inputs[1].placeholder : categoriesData.categories[wizardStateData.steps[stepIndex].industryIndex].subcategories[wizardStateData.steps[stepIndex].subIndustryIndex].title}
                        value={wizardStateData.steps[stepIndex].subIndustryChecked.length == 0 ? data.inputs[1].placeholder : wizardStateData.steps[stepIndex].subIndustryChecked.map(i => i.title).join(", ")}
                        items={wizardStateData.steps[stepIndex].industryIndex != -1 ? categoriesData.categories[wizardStateData.steps[stepIndex].industryIndex].subcategories : null}
                        onClick={handleSubIndustryCheckChange}
                        showError={showDropdown2ErrorMsg}
                        showItems={wizardStateData.steps[stepIndex].industryIndex != -1}
                        checkedItems={wizardStateData.steps[stepIndex].subIndustryChecked}
                    />
                </InputErrorWrapper>
            </Form.Group>
        </>
    )

}

export default Step2;




// OLD: data.inputs[1].options[wizardStateData.steps[stepIndex].industryIndex].options -> categoriesData.categories[wizardStateData.steps[stepIndex].industryIndex]


{/* <div className="dropdown mt-1" >
                        <button
                            className="btn-sm btn-outline-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{ width: '100%', justifyContent: 'space-between' }}
                        >
                            <span style={{ alignSelf: 'flex-start', color: wizardStateData.steps[stepIndex].industryIndex === -1 ? '#afafc2' : '#221e54' }}>
                                {wizardStateData.steps[stepIndex].industryIndex === -1 ? data.inputs[0].placeholder : categoriesData.categories[wizardStateData.steps[stepIndex].industryIndex].title}
                            </span>
                        </button>
                        <div class="invalid-feedback">Example invalid select feedback</div>

                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ width: '100%' }}>
                            {categoriesData.categories.map((item, index) => (
                                <button id={index} className="dropdown-item btn-link" onClick={() => handleIndustryChange(index)} >
                                    <span className="dropdown-text">
                                        {item.title}
                                    </span>
                                </button>
                            ))}
                        </div>
                    </div> */}



{/* Custom Error message */ }
                    //  {showDropdown1ErrorMsg && (
                    //     <div style={{ color: '#dc3545', width: '100%', marginTop: '0.25rem', fontSize: '80%', fontWeight: 400 }}>
                    //         {data?.inputs[0].error_message}
                    //     </div>
                    // )}






                    // <Form.Group>
                    //     <Form.Label>
                    //         {data?.inputs[0].label}
                    //     </Form.Label>
                    //     <div className="dropdown mt-1" >
                    //         <InputGroup hasValidation>
                    //             <Dropdown.Toggle id="dropdown-basic"
                    //                 className="btn-sm btn-outline-secondary dropdown-toggle"
                    //                 id="dropdownMenuButton"
                    //                 data-toggle="dropdown"
                    //                 style={showDropdown1ErrorMsg ? { width: '100%', justifyContent: 'space-between', borderColor: '#dc3545' } : { width: '100%', justifyContent: 'space-between' }}
                    //             >
                    //                 <span style={{ alignSelf: 'flex-start', color: wizardStateData.steps[stepIndex].industryIndex === -1 ? '#afafc2' : '#221e54' }}>
                    //                     {wizardStateData.steps[stepIndex].industryIndex === -1 ? data.inputs[0].placeholder : categoriesData.categories[wizardStateData.steps[stepIndex].industryIndex].title}
                    //                 </span>
                    //             </Dropdown.Toggle>


                    //             <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ width: '100%' }}>
                    //                 {categoriesData.categories.map((item, index) => (
                    //                     <button type="button" id={index} key={index} className="dropdown-item btn-link" onClick={() => handleIndustryChange(index)} >
                    //                         <span className="dropdown-text">
                    //                             {item.title}
                    //                         </span>
                    //                     </button>
                    //                 ))}
                    //             </div>
                    //         </InputGroup>
                    //     </div>

                    // </Form.Group>



                //     <div>
                //     <Form.Group>
                //         <Form.Label>
                //             {data.inputs[1].label}
                //         </Form.Label>
                //         <div className="dropdown mt-1" >
                //             <InputGroup hasValidation className="custom-input-field">
                //                 <Dropdown.Toggle id="dropdown-basic"
                //                     className="btn-sm btn-outline-secondary dropdown-toggle"
                //                     id="dropdownMenuButton"
                //                     data-toggle="dropdown"
                //                     aria-haspopup="true"
                //                     aria-expanded="false"
                //                     style={showDropdown2ErrorMsg ? { width: '100%', justifyContent: 'space-between', borderColor: '#dc3545' } : { width: '100%', justifyContent: 'space-between' }}
                //                 >
                //                     <span style={{ alignSelf: 'flex-start', color: wizardStateData.steps[stepIndex].subIndustryIndex === -1 ? '#afafc2' : '#221e54' }}>
                //                         {wizardStateData.steps[stepIndex].subIndustryIndex === -1 ? data.inputs[1].placeholder : categoriesData.categories[wizardStateData.steps[stepIndex].industryIndex].subcategories[wizardStateData.steps[stepIndex].subIndustryIndex].title}
                //                     </span>
                //                 </Dropdown.Toggle>

                //                 {/* Custom Error message */}
                //                 {showDropdown2ErrorMsg && (
                //                     <ErrorMessage errorMessage={data?.inputs[1].error_message} />
                //                 )}
                //                 <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ width: '100%' }}>
                //                     {wizardStateData.steps[stepIndex].industryIndex != -1 && (
                //                         <>
                //                             {
                //                                 categoriesData.categories[wizardStateData.steps[stepIndex].industryIndex].subcategories.map((item, index) => (
                //                                     <button type="button" id={index} value="" key={index} className="dropdown-item btn-link" onClick={() => handleSubIndustryChange(index)} >
                //                                         <span className="dropdown-text">
                //                                             {item.title}
                //                                         </span>
                //                                     </button>
                //                                 ))
                //                             }
                //                         </>
                //                     )}
                //                 </div>
                //             </InputGroup>
                //         </div>
                //     </Form.Group>
                // </div>
