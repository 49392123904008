/* eslint-disable */
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive'

export function IsDesktop() {
    // return useMediaQuery({ query: '(min-width: 769px)' });
    const properties = useSelector(state => state.properties.data);
    return (properties.device === 'desktop')
}

export function IsTablet() {
    // return useMediaQuery({ query: '(min-width: 576px) and (max-width: 768px)' });
    const properties = useSelector(state => state.properties.data);
    return (properties.device === 'tablet')
}

export function IsPhone() {
    // return useMediaQuery({ query: '(max-width: 575px)' });   //useMediaQuery({ query: '(max-width: 375px)' });
    const properties = useSelector(state => state.properties.data);
    return (properties.device === 'phone')
}

