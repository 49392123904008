/* eslint-disable */
import React from "react";

import CircleIcon from "../../../../assets/icons/circle-indigo.png";
import ChevronIcon from "../../../../assets/icons/right-chevron.png";

function PhoneStepper({ steps, activeStep, handleStepClick }) {
  const styles = {
    leftButton: {
      width: 50,
      transform: "scaleX(-1)",
    },
    rightButton: {
      width: 50,
    },
    buttonDiv: {
      display: "inline-block",
      width: 50,
      height: 50,
    },
    leftHorizontalLine: {
      display: "flex",
      height: 3,
      background: "#1d1d1f",
      flexGrow: 1,
      margin: "0px 10px",
    },
    rightHorizontalLine: {
      display: "flex",
      height: 3,
      background: "#6fc6ef",
      flexGrow: 1,
      margin: "0px 10px",
    },
    invisibleHorizontalLine: {
      display: "flex",
      opacity: 0,
      flexGrow: 1,
      margin: "0px 10px",
    },
    stepCircle: {
      width: 45,
      height: 45,
      background: `url(${CircleIcon})`,
      backgroundSize: "100% 100%",

      // Text
      color: "#FFFFFF",
      fontWeight: 900,
      fontSize: 24,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  return (
    <div className="mt-2 mb-5">
      {activeStep >= 0 && activeStep < steps.length && (
        <>
          <div className="flex-row d-flex justify-content-between align-items-center">
            {/* Left button */}
            <div style={styles.buttonDiv}>
              {activeStep !== 0 && (
                <img
                  src={ChevronIcon}
                  alt={"Chevron"}
                  style={styles.leftButton}
                  onClick={() => handleStepClick(activeStep - 1)}
                />
              )}
            </div>

            <span
              style={
                activeStep === 0
                  ? styles.invisibleHorizontalLine
                  : styles.leftHorizontalLine
              }
            />

            <div style={styles.stepCircle}>
              <div>{activeStep + 1}</div>
            </div>

            <span
              style={
                activeStep === steps.length - 1
                  ? styles.invisibleHorizontalLine
                  : styles.rightHorizontalLine
              }
            />

            {/* Right button */}
            <div style={styles.buttonDiv}>
              {activeStep !== steps.length - 1 && (
                <img
                  src={ChevronIcon}
                  alt={"Chevron"}
                  style={styles.rightButton}
                  onClick={() => handleStepClick(activeStep + 1)}
                />
              )}
            </div>
          </div>

          <div class="d-flex justify-content-center mt-1 mb-1">
            Чекор {activeStep + 1}/{steps.length}
          </div>

          <div
            class="d-flex justify-content-center fw-900 c-indigo"
            style={{ fontSize: 19 }}
          >
            {steps[activeStep]}
          </div>
        </>
      )}
    </div>
  );
}

export default PhoneStepper;

// OLD: data.inputs[1].options[wizardStateData.steps[stepIndex].industryIndex].options -> categoriesData.categories[wizardStateData.steps[stepIndex].industryIndex]
