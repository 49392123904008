export function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    if (value[0] !== "0" && value[0] === "7") {
        return `0${value}`;
    }

    if (value[0] !== "0" && value[0] !== "7") {
        return value[0];
    }

    if(value.length > 1 && value[0] ==="0" && value[1] !=="7" ){
        return `${value[0]}${value[1]}`
    }

    const phoneNumber = value.replace(/[^\d]/g, "");

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }


    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        6
    )}-${phoneNumber.slice(6, 9)}`;
}