// import { palette } from 'services/theme/theme-constants';
import { palette } from './../../theme/theme-constants';

export const uploadUsersOverrides = {
    MuiButton: {
        root: {
            '&.download-template-users': {
                backgroundColor: palette.secondary.light,
                outline: 'none',
                textTransform: 'none',
                padding: '2px 8px',
            },
        },
    },
    MuiDropzoneArea: {
        root: {
            backgroundColor: palette.background.dropzone,
        },
    },
};
