import React from 'react';
import shoppingCartImage from '../../../../../assets/icons/shopping-cart.png';

function Summary({ summaryLabels }) {

    return (
        <>
        <div class="col-md-6">
            <div className="pl-4">
                <span className="c-indigo fw-300">{summaryLabels.order_summary}</span>
                <div className="row mt-3">
                    <div className="d-flex-block">
                        <img width={'170px'} src={shoppingCartImage} alt={'Shoping card'} />
                    </div>
                    <div className="d-flex-block ml-4">
                        <p className="c-indigo fw-300 mb-1">Vendor веб-продавница</p>
                        <div className="c-magenta">
                            <span className="fw-900 fs-22 mr-1">1, 800</span>
                            <span>ден</span>
                        </div>
                        <p className="c-gray fs-10 fw-100">месечна претплата</p>
                    </div>
                </div>
            </div>

            {/* Износ */}
            <div className="total-price mt-3">
                <div className="justify-content-between d-flex mb-3">
                    <span>{summaryLabels.amount}</span>
                    <span>1,800 ден.</span>
                </div>
                <div className="justify-content-between d-flex mb-3">
                    <span>{summaryLabels.additional_services}</span>
                    <span>0 ден.</span>
                </div>
                <div className="justify-content-between d-flex">
                    <span>{summaryLabels.tax}</span>
                    <span>34 ден.</span>
                </div>
                <hr className="custom-hr" />
                <div className="justify-content-between d-flex">
                    <span className="fw-900">{summaryLabels.total}</span>
                    <span className="fw-900">1,834 ден. / месечно</span>
                </div>
                <p className="fw-100 fs-14 mt-4">*{summaryLabels.disclaimer}</p>
            </div>
            </div>
        </>
    )
}

export default Summary;