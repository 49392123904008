import React from "react";

import heroBanner from "./../../assets/images/vendor-hero-2.png";
import robotAssistant from "./../../assets/images/robot.svg";
import robotWizardFlow from "./../../assets/images/robot-wizard.svg";

import { Helmet } from "react-helmet";
import Header from "../components/landing/Header";
import Logos from "./../components/landing/logos";
import Section1 from "./../components/landing/section_1";
import Section2 from "./../components/landing/section_2";
import Section3 from "./../components/landing/section_3";
import Section4 from "./../components/landing/section_4";
import Section5 from "./../components/landing/section_5";
import Section6 from "./../components/landing/section_6";
import Section7 from "./../components/landing/section_7";
import Section9 from "./../components/landing/section_9";
import Footer from "./../components/landing/footer";
import Marquee from "react-fast-marquee";
import integrationsData from "./../../assets/api/mk/integrations.json";

import Timeline from "../components/landing/Timeline";

import landingData from "../../assets/api/mk/front.json";

function HomePage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet titleTemplate="Vendor - %s">
        <meta charSet="utf-8" />
        <title>Rethink selling</title>
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta name="author" content="Vendor" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />

        <meta property="og:title" content="Vendor - Rethink selling" />
        <meta
          property="og:description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta
          property="og:image"
          content="https://vendor.com.mk/facebook-thumbnail.jpg"
        />
        <meta property="og:url" content="https://vendor.com.mk/" />

        <meta name="twitter:title" content="Vendor - Rethink selling" />
        <meta
          name="twitter:description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta
          name="twitter:image"
          content="https://vendor.com.mk/twitter-thumbnail.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div>
        <Header />

        <Section1
          title={landingData?.section_1?.title}
          tagline={landingData?.section_1?.tagline}
          tagline_1={landingData?.section_1?.tagline_1}
          tagline2={landingData?.section_1?.tagline_2}
          button_label={landingData?.section_1?.button_label}
          img={heroBanner}
          key={"seciont 1"}
          videoDesktop={landingData?.section_1?.video_desktop}
          videoTablet={landingData?.section_1?.video_tablet}
          videoMobile={landingData?.section_1?.video_mobile}
        />
        <Marquee speed={60} gradient={false}>
          {integrationsData?.posts?.map((item, index) => {
            return (
              <img
                style={{
                  borderRadius: "10px",
                  // filter: "grayscale(100)",
                  // opacity: "50%",
                }}
                className="logo-marquee"
                src={item.image}
                alt={"img"}
              />
            );
          })}
        </Marquee>
        {/*
          <HorizontalTimeline
						title={landingData?.section_8?.title}
						text={landingData?.section_8?.text}
						background={landingData?.section_8?.background}
						timelineData={landingData?.section_8?.timeline}
					></HorizontalTimeline> */}

        <Timeline
          background={landingData?.section_8?.background}
          text={landingData?.section_8?.text}
          title={landingData?.section_8?.title}
          timeline={landingData?.section_8?.timeline}
        />

        {/* <Section9
          title={landingData?.section_9?.title}
          text={landingData?.section_9?.text}
          video={landingData?.section_9.video}
        /> */}

        <Section2
          testimonials={landingData?.section_2?.testimonials}
          imageUrl={landingData?.section_2?.image}
          logoUrl={landingData?.section_2?.logo}
          key={"seciont 2"}
        />

        <Section3
          features={landingData?.section_3?.features}
          image={landingData?.section_3?.image}
          key={"seciont 3"}
        />

        <Section4
          title={landingData?.section_4?.title}
          tagline={landingData?.section_4?.tagline}
          steps={landingData?.section_4?.steps}
          button_label={landingData?.section_4?.button_label}
          key={"seciont 4"}
        />

        <Section5
          title={landingData?.section_5?.title}
          text={landingData?.section_5?.text}
          robotWizardFlow={robotWizardFlow}
          robotAssistant={robotAssistant}
          key={"seciont 5"}
        />

        {/* <Section7
          title={landingData?.section_7?.title}
          text={landingData?.section_7?.text}
          promo={landingData?.section_7?.promo}
          key={"seciont 7"}
        /> */}

        <Section6
          title={landingData?.section_6?.title}
          button_label={landingData?.section_6?.button_label}
          key={"seciont 6"}
        />

        <Footer />
      </div>
    </div>
  );
}

export default HomePage;
