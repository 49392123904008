/* eslint-disable */
import * as React from "react";
import logoColored from "../../../assets/images/vendor-logo-color.svg";
import languageSwitcherIcon from "../../../assets/images/globe.svg";
import en from "../../../assets/icons/gb.svg";
import mk from "../../../assets/icons/mk.svg";
import al from "../../../assets/icons/al.svg";
import loginIcon from "../../../assets/images/login.svg";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { LocaleContext } from "../../../App.js";
import { NavDropdown, MenuItem } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Header(props) {
  const { locale, setLocale } = React.useContext(LocaleContext);
  const { t } = useTranslation();
  const handleLangChange = (lng) => {
    setLocale(lng);
    i18n.changeLanguage(lng);
  };
  return (
    <div className="menu-wrapper" id="header">
      <nav className="navbar navbar-expand-lg navbar-light p-0">
        <div className="shadow1 w-100 h-100 align-items-center d-flex">
          <div className="container big-container h-100 text-center position-relative">
            <div className="py-2">
              <Link to="/">
                <img width={"150px"} src={logoColored} alt={"logo colored"} />
              </Link>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse my-lg-0 my-3"
              id="navbarSupportedContent"
            >
              <div className="text-center middle-menu-wrapper  c-gray d-flex flex-lg-row flex-column align-items-start align-items-lg-center justify-content-center">
                <a
                  className="menuItem py-lg-0 py-2 my-lg-0 my-md-2 my-1"
                  href="https://why.vendor.com.mk"
                  target="_blank"
                >
                  <p className="fw-300 mb-0 mr-4 " style={{ fontSize: 16 }}>
                    {t("header.vendor")}
                  </p>
                </a>
                <Link
                  className="menuItem py-lg-0 py-2 my-lg-0 my-md-2 my-1"
                  to="/plans"
                >
                  <p className="fw-300 mb-0 mr-4 " style={{ fontSize: 16 }}>
                    {t("header.plans")}
                  </p>
                </Link>

                <Link
                  className="menuItem py-lg-0 py-2 my-lg-0 my-md-2 my-1"
                  to="/partners"
                >
                  <p className="fw-300 mb-0 mr-4 " style={{ fontSize: 16 }}>
                    {t("header.partners")}
                  </p>
                </Link>
                {/* <Link className="menuItem py-lg-0 py-2 my-lg-0 my-md-2 my-1" to="/customer-service"><p className="fw-300 mb-0 mr-4 "
                                                                                                style={{ fontSize: 16 }}>{t(
                    'header.customer-service')}</p></Link> */}
                {/* <a
                  className="menuItem py-lg-0 py-2 my-lg-0 my-md-2 my-1"
                  href="https://insights.vendor.com.mk"
                  target="_blank"
                >
                  <p className="fw-300 mb-0 mr-4 " style={{ fontSize: 16 }}>
                    Insights
                  </p>
                </a> */}
              </div>

              <div className="d-flex align-items-center py-3">
                {/* <NavDropdown
                  className="mr-3"
                  id="language-picker"
                  title={
                    <img
                      width={"28px"}
                      className="mr-3"
                      src={languageSwitcherIcon}
                      alt={"customer service icon"}
                    />
                  }
                >
                  <NavDropdown.Item
                    onClick={() => handleLangChange("mk")}
                    active={locale === "mk"}
                  >
                    <img
                      width={"18px"}
                      className="mr-3"
                      src={mk}
                      alt={"customer service icon"}
                    />
                    {t("header.languagePicker.mk")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => handleLangChange("en")}
                    active={locale === "en"}
                  >
                    <img
                      width={"18px"}
                      className="mr-3"
                      src={en}
                      alt={"customer service icon"}
                    />
                    {t("header.languagePicker.en")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => handleLangChange("sq")}
                    active={locale === "sq"}
                  >
                    <img
                      width={"18px"}
                      className="mr-3"
                      src={al}
                      alt={"customer service icon"}
                    />
                    {t("header.languagePicker.sq")}
                  </NavDropdown.Item>
                </NavDropdown> */}
                {/* <Link to="/register-business"><img width={'28px'} className="mr-3" src={loginIcon} alt={"login icon"}/></Link> */}
                <Link
                  to="/register-business"
                  id="header-register-button"
                  className="header-button"
                >
                  {t("header.register")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
