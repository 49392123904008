/* eslint-disable */
import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./footer";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import TextInput from "../inputs/TextInput";
import TextArea from "../inputs/TextArea";
import { useTranslation } from "react-i18next";
// import customerServiceData from '../../assets/api/mk/customer-service.json'

import data from "../../../../src/assets/api/mk/customer-service.json";

function CustomerService(props) {
  const [show, setShow] = useState(false);

  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isLoading, setIsLoading] = useState(true);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div>
        <Header></Header>
        <div className="insightsWrapper mb-4 py-5">
          <div className="container">
            {/* Title */}
            <div class="">
              <div className=" justify-content-start">
                <h3 className="custom-label ">{t("customerService.header")}</h3>
                {/* Description */}
                <h6 className="c-indigo" style={{ fontSize: 16 }}>
                  {t("customerService.subHeader")}
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row">
            <div className="row w-100 my-md-1 my-4 align-items-center">
              <div className="col-md-11 mx-auto">
                <div className="row mx-0 align-items-center ">
                  <div className="col-md-5 text-center">
                    <img
                      className="img-fluid"
                      src={data?.image}
                      alt="customer service img"
                    />
                  </div>

                  <div className="col-md-7 pl-5">
                    <h2 className="fs-36 c-indigo fw-900">
                      {data?.HelpCenterTitle}
                    </h2>
                    <p className="max-width-430 fs-16 fw-100">
                      {data?.HelpCenterSubtitle}
                    </p>
                    <p class="c-magenta">
                      Центарот за корисничка поддршка доаѓа наскоро.
                    </p>
                    {/* <a className="blue-button fs-20 my-2 d-inline-block" target="_blank" href="https://support.vendor.com.mk">
                        {data?.open_ticket_button}
                      </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-pale-blue">
          <div className="container py-5">
            <h1 className="w-100 fs-36 fw-900 c-indigo text-center my-3">
              {t("customerService.contactUs")}
            </h1>

            <div className="row pb-5 ">
              {data.departments.map((department) => {
                return (
                  <div className="col-md-4 col-sm-6 d-flex">
                    <div className="min-width-210">
                      <h6 className="fs-21 pb-3 fw-900 c-indigo customer-service-contact-us-heading mb-2">
                        {department.title}
                      </h6>
                      <p className="mb-0">{department.email}</p>
                      <p className="mb-0">{department.phone}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    </div>
  );
}

export default CustomerService;
