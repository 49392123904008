/* eslint-disable */
import React, { useEffect, useState } from "react";

import heroBanner from "./../../assets/images/vendor-hero-2.png";
import robotAssistant from "./../../assets/images/robot.svg";
import robotWizardFlow from "./../../assets/images/robot-wizard.svg";
import { Helmet } from "react-helmet";

import Header from "../components/landing/Header";
import Logos from "../components/landing/logos";
import Footer from "../components/landing/footer";
import Section6 from "../components/landing/section_6";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import axios from "axios";
import { stateIsLoaded } from "../../services/stateHelpers";
import { useDispatch, useSelector } from "react-redux";
import { getInsightsAction } from "../../redux/actions/vendorActions";
import { data } from "jquery";
import { useHistory, useParams } from "react-router-dom";
import { GET_SINGLE_INSIGHT_API } from "../../services/api";
import { browserHistory } from "react-router";
import * as qs from "query-string";

import landingData from "../../assets/api/mk/front.json";
import modulesData from "../../assets/api/mk/modules.json";

function Insights(props) {
  const { push } = useHistory();
  let { params } = useParams();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [isInsightShown, setIsInsightShown] = useState(false);
  const [shownInsightId, setShownInsightId] = useState(0);

  // const insightClicked = (id) => {
  //   setIsInsightShown(true);
  //   setShownInsightId(id);
  //   fetchInsight(id);
  //   push('/insights?insight=' + id)
  // }

  const handleBackClick = () => {
    console.log("BACK " + isInsightShown);
    if (isInsightShown) setIsInsightShown(false);
  };

  const { listen } = useHistory();
  useEffect(() => {
    const unlisten = listen((location) => {
      handleBackClick();
    });

    // remember, hooks that add listeners
    // should have cleanup to remove them
    return unlisten;
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet titleTemplate="Vendor - %s">
        <meta charSet="utf-8" />
        <title>Модули</title>
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta name="author" content="Vendor" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />

        <meta property="og:title" content="Vendor - Rethink selling" />
        <meta
          name="description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta
          property="og:image"
          content="https://vendor.com.mk/facebook-thumbnail.jpg"
        />
        <meta property="og:url" content="https://vendor.com.mk/" />

        <meta name="twitter:title" content="Vendor - Rethink selling" />
        <meta
          name="twitter:description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta
          name="twitter:image"
          content="https://vendor.com.mk/twitter-thumbnail.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div>
        <Header></Header>

        <div className="insightsWrapper mb-4 py-5">
          <div className="container">
            {/* Title */}
            <div class="">
              <div className=" justify-content-start">
                <h3 className="custom-label ">{t("modules.header")}</h3>
                {/* Description */}
                <h6 className="c-indigo" style={{ fontSize: 16 }}>
                  {t("modules.subHeader")}
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          {/* All insights or single insight */}
          {(!isInsightShown && (
            <div>
              {/* Insights listed */}
              <div>
                {modulesData?.posts?.map((item, index) => {
                  return (
                    <div className="mb-5 d-flex row border-bottom align-items-center p-4">
                      <div
                        className="col-md-7"
                        style={{ flexDirection: "column" }}
                      >
                        <h2
                          className="fs-23 fw-900 c-indigo"
                          style={{ display: "block" }}
                        >
                          <a>{item.title}</a>
                        </h2>
                        <p className="c-gray mb-2">{parse(item.excerpt)}</p>
                        {/* <a href={item.slug} target="_blank" className="header-button">Прочитај повеќе</a> */}
                      </div>
                      <div className="col-md-5 p-0">
                        <div class="row mx-0 mt-2">
                          {/* <div class="col-6 border-right text-center">
                                <p class="c-darker-blue">месечно плаќање</p>
                                <p class="c-indigo">{item.monthly_price}</p>
                            </div>
                            <div class="col-6 text-center">
                                <p class="c-darker-blue">годишно плаќање</p>
                                <p class="c-indigo">{item.yearly_price}</p>
                            </div> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )) || (
            <div>
              {(isLoading && (
                <div class="loader-wrapper">
                  <img src="/loader.svg" class="loader"></img>
                </div>
              )) || (
                <div>
                  <h3 className="fs-21 fw-900 c-indigo pt-0">
                    {insightData.title}
                  </h3>
                  <div className="col-md-6 p-0 mb-3 mt-3">
                    <img
                      width={"100%"}
                      style={{ borderRadius: "10px" }}
                      src={insightData.image}
                      alt={"img"}
                    />
                  </div>
                  <p className="col-md-6 c-gray mb-2 pt-3">
                    {parse(insightData.content)}
                  </p>
                </div>
              )}
            </div>
          )}

          <Section6
            title={landingData?.section_6?.title}
            button_label={landingData?.section_6?.button_label}
            key={"seciont 6"}
          />
        </div>
        <Footer />
      </div>
      {/* )} */}
    </div>
  );
}

export default Insights;
