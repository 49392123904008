/* eslint-disable */
import { Checkbox, Divider, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { DropdownButton, Form, InputGroup, Dropdown } from "react-bootstrap";

function CustomDropdownCheckbox({ onClick, value, items, checkedItems, label, showError = false, disabled = false, showItems = true }) {
    return (
        <Form.Group>
            <Form.Label>
                {label}
            </Form.Label>
            <div className="dropdown mt-1" >
                <InputGroup hasValidation>
                    <Dropdown.Toggle
                        className="btn-sm btn-outline-secondary dropdown-toggle"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        style={showError ? { width: '100%', justifyContent: 'space-between', borderColor: '#dc3545' } : { width: '100%', justifyContent: 'space-between' }}
                    >
                        <span style={{ alignSelf: 'flex-start', color: disabled === -1 ? '#afafc2' : '#221e54' }}>
                            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', marginRight: 20 }}>
                                {value}
                            </div>
                        </span>
                    </Dropdown.Toggle>

                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ width: '100%', flexDirection: 'column' }}>
                        {showItems && items.map((item, index) => (
                            // <button type="button" id={index} key={index} className="dropdown-item btn-link" onClick={() => onClick(index)} >
                            //     <span className="dropdown-text">
                            //         {item.title}
                            //     </span>
                            // </button>
                            <FormControlLabel
                                style={{ width: '100%', marginBottom: 0, marginLeft: 5 }}
                                // onClick={() => { onClick(item.title) }}
                                control={
                                    <Checkbox
                                        checked={checkedItems.map(o => o.title).includes(item.title)}
                                        onChange={() => onClick(
                                            { title: item.title, id: item.id }
                                        )}
                                        name="checkedB"
                                        color='primary'
                                    />
                                }
                                label={JSON.stringify(item.title)}
                            />
                        ))}
                    </div>
                </InputGroup>
            </div>

        </Form.Group>
    )
}

export default CustomDropdownCheckbox;

