/* eslint-disable */
import { combineReducers } from 'redux';
import { basicReducer } from './utils/basic.reducer';
import {
    GET_WIZARD,
    GET_CATEGORIES,
    GET_MODELS,
    WIZARD_STATE,
    PROPERTIES,
    GET_PRICING_TABLE,
    GET_INSIGHTS,
} from '../constants/main.constants';
import wizardState from './wizardState.reducer'

const rootReducer = combineReducers({
    wizard: basicReducer(GET_WIZARD),
    categories: basicReducer(GET_CATEGORIES),
    models: basicReducer(GET_MODELS),
    wizardState: wizardState(WIZARD_STATE),
    properties: basicReducer(PROPERTIES),
    pricingTable: basicReducer(GET_PRICING_TABLE),
    insights: basicReducer(GET_INSIGHTS),
});

export default (state, action) => rootReducer(state, action);
