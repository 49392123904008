/* eslint-disable */
import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import { validateEmail } from '../../../../services/StringHelpers';
import CustomPhoneInput from '../../inputs/CustomPhoneInput';
import ErrorMessage from '../../inputs/ErrorMessage';
import InputErrorWrapper from '../../inputs/InputErrorWrapper';
import TextInput from '../../inputs/TextInput';

function Step1({ data, stepIndex, wizardStateData, updateWizardState }) {

    const [fix, setFix] = useState('');

    const handleInputChange = (value, index) => {
        const key = Object.keys(wizardStateData.steps[stepIndex])[index];
        wizardStateData.steps[stepIndex][key] = value;

        wizardStateData.steps[stepIndex].allFieldsEntered = (wizardStateData.steps[stepIndex].firstName.length > 0 &&
            wizardStateData.steps[stepIndex].lastName.length > 0
            && validateEmail(wizardStateData.steps[stepIndex].email)
            && wizardStateData.steps[stepIndex].phone.length >= 11
        );
        updateWizardState()
        setFix(value);
    }

    const getValue = (index) => {
        const key = Object.keys(wizardStateData.steps[stepIndex])[index];
        return wizardStateData.steps[stepIndex][key];
    }

    const showErrorMessage = (index) => {
        if (wizardStateData.steps[stepIndex].successfulValidation)
            return false;

        if (index === 0)
            return wizardStateData.steps[stepIndex].firstName.length == 0;
        else if (index === 1)
            return wizardStateData.steps[stepIndex].lastName.length == 0;
        else if (index === 2)
            return wizardStateData.steps[stepIndex].phone.length < 11;
        else
            return !validateEmail(wizardStateData.steps[stepIndex].email);
    }


    return (
        <>
            <Form.Group id="step1" style={{ alignItems: "flex-start" }} className="mt-4" >
                {data?.inputs?.map((item, index) => {
                    return (
                        <InputErrorWrapper id={"Step1-input-" + index} className="mb-4" errorMessage={item.error_message} showError={showErrorMessage(index)}>
                            {item.inputType !== 'phoneNumber' && (
                                <TextInput label={item.label} value={getValue(index)} placeholder={item.placeholder} onChange={handleInputChange} required={item.required} index={index} showError={showErrorMessage(index)} />
                            ) || (
                                    <CustomPhoneInput label={item.label} value={getValue(index)} onChange={handleInputChange} required={item.required} index={index} showError={showErrorMessage(index)} />
                                )}
                        </InputErrorWrapper>
                    );
                })}
            </Form.Group>
        </>
    )

}

export default Step1;




{/* <Form.Control.Feedback type="invalid">
                                    {item.error_message}
                                </Form.Control.Feedback> */}
{/* Custom Error message */ }

{/* <Form.Label className="">{item.label}</Form.Label> */ }
{/* <InputGroup hasValidation> */ }


// <PhoneInput
                                    //     style={{ display: 'flex' }}
                                    //     country={'mk'}
                                    //     international
                                    //     value={getValue(index)}
                                    //     // onChange={(value) => { handleInvoiceTextChange(value, 3) }}
                                    //     onChange={(value) => { handleInputChange(value, index) }}
                                    //     style={showErrorMessage(index) ? { borderColor: '#dc3545' } : {}}
                                    // />

                                    // <Form.Control type={item.inputType} value={getValue(index)} placeholder={item.placeholder} className="dropdown-custom" onChange={(event) => { handleInputChange(event.target.value, index) }} required={item.required} style={showErrorMessage(index) ? { borderColor: '#dc3545' } : {}} />



// NEWER

// <div style={{ position: 'relative' }}>
//                             {item.inputType !== 'phoneNumber' && (
//                                 <TextInput label={item.label} placeholder={item.placeholder} onChange={handleInputChange} required={item.required} index={index} showError={showErrorMessage(index)} />
//                             ) || (
//                                     <CustomPhoneInput label={item.label} value={getValue(index)} onChange={handleInputChange} required={item.required} index={index} showError={showErrorMessage(index)} />
//                                 )}
//                             {showErrorMessage(index) && (
//                                 <ErrorMessage errorMessage={item.error_message} />
//                             )}
//                         </div>
