/* eslint-disable */
import { buildActionType } from './utils/buildActionType';
import { ActionStatus } from '../core/ActionStatus'
import ActionExecutor from './utils/ActionExecutor';
import ApiExecutor from '../../services/Api/ApiExecutor';

import {
    GET_WIZARD_API,
    GET_CATEGORIES_API,
    GET_MODELS_API,
    GET_PRICING_TABLE_API,
    GET_INSIGHTS_API,
} from '../../services/api.js';
import {
    GET_WIZARD,
    GET_CATEGORIES,
    GET_MODELS,
    WIZARD_STATE,
    PROPERTIES,
    GET_PRICING_TABLE,
    LLL,
    GET_INSIGHTS,
} from '../constants/main.constants';

import MockedWizard from '../../assets/jsons/mockedWizard.json'
import { useMediaQuery } from '@material-ui/core';


export const getWizardAction = () => async dispatch => {
    dispatch(ActionExecutor(ApiExecutor('get', GET_WIZARD_API), GET_WIZARD));
};


export const mockWizardAction = () => async dispatch => {
    dispatch({
        type: buildActionType(GET_WIZARD, ActionStatus.DONE),
        payload: MockedWizard,
        responseStatus: 3,
    });
};

export const getCategoriesAction = () => async dispatch => {
    dispatch(ActionExecutor(ApiExecutor('get', GET_CATEGORIES_API), GET_CATEGORIES));
};

export const getPricingTable = () => async dispatch => {
    dispatch(ActionExecutor(ApiExecutor('get', GET_PRICING_TABLE_API), GET_PRICING_TABLE));
};

export const getModelsAction = (query) => async dispatch => {
    dispatch(ActionExecutor(ApiExecutor('get', GET_MODELS_API + query), GET_MODELS));
};

export const initializeWizardState = () => async dispatch => {
    dispatch({ type: buildActionType(WIZARD_STATE, ActionStatus.START) });
};

export const updateWizardState = (newData) => async dispatch => {
    dispatch({
        type: buildActionType(WIZARD_STATE, ActionStatus.DONE),
        payload: newData,
    });
};

export const SetProperties = (width, currentDevice) => async dispatch => {
    let device = '';
    if (width > 820)            // Old: 769
        device = 'desktop';
    else if (width > 575)
        device = 'tablet';
    else
        device = 'phone';

    const data = {
        device: device,
    }

    dispatch({
        type: buildActionType(PROPERTIES, ActionStatus.DONE),
        payload: data,
    });
};


