/* eslint-disable */
import React, { useEffect, useState } from "react";
import Footer from "./../components/landing/footer";
import { useHistory } from "react-router-dom";
import Header from "../components/landing/Header";

import Lottie from "lottie-react";
import thumbsUpRobot from "./../../assets/animations/thumbup.json";

function LeadSuccessful() {
    const { push } = useHistory()
    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
       <Header></Header>
                <div className="container">
            <div className="my-5">
                <div className="row mx-0">
                <div className="col-md-4 col-sm-4 robot-wrap mx-auto">
                        <Lottie className="" animationData={thumbsUpRobot} />
                    </div>
                    <div class="col-md-10 mx-auto">
                        <h3 className="custom-label  text-center mb-4 fs-36 c-dark-blue fw-900">Успешна регистрација!</h3>
                        <p className="c-gray  fw-100 text-center mb-3 c-dark fw-100 lh-27 fs-17 max-width-750" style={{ margin: '0 auto' }}>
                            На твојата е-маил адреса ќе добиеш порака за активација на твојот Vendor MultiPass.
                          <br/>Tи благодариме!</p>
                        <div className="w-100  mb-5 text-center pb-5">
                            <button className="blue-button fs-18 mt-3 text-center" onClick={() => push('/')}>
                                Назад кон Vendor
                            </button>
                        </div>
                    </div>


                </div>
            </div>
            </div>

            <Footer></Footer>
        </div>
    )
}

export default LeadSuccessful;
