/* eslint-disable */
import React from 'react';

import Lottie from "lottie-react";
import idleRobot from "../../../assets/animations/idle.json";

import Step1 from './steps/Step1'
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';
import Step6 from './steps/Step6';
import { IsDesktop } from '../../../services/CustomMediaQueries';

import Robot from './steps/Robot'

function WizardSteps({ data, stepIndex, wizardStateData, updateWizardStateStep, updateWizardState }) {

    let lottieWidth = 150;

    let styles = {
        wrapper: {
            paddingTop: 30,
            paddingBottom: 30,
            // paddingLeft: 120,
            // paddingRight: 120,
            paddingLeft: 150,
            paddingRight: 150,
            minHeight: '400px',
        },
        description: {
            fontSize: 16,
        },
        plans: {
            // marginLeft: -50,
            // marginRight: -50,
            flexDirection: 'row-reverse',
            // paddingLeft: '100px',
            // paddiingRight: '100px',
            // alignItems: 'stretch',
            // minHeight: '900px'
            // justifyContent: 'space-around',
        },

        lottiePosition: {
            left: -lottieWidth / 2,
            top: 50,
        },
    }

    const isDesktop = IsDesktop();

    return (
        <div>
            {/* Steps */}
            {stepIndex === 0 && (
                <Step1 data={data[stepIndex]} key={stepIndex} stepIndex={stepIndex} wizardStateData={wizardStateData} updateWizardState={updateWizardState} />
            )}
            {stepIndex === 1 && (
                <Step2 data={data[stepIndex]} key={stepIndex} stepIndex={stepIndex} wizardStateData={wizardStateData} />
            )}
            {/* Bellow changed 3->2 */}
            {stepIndex === 2 && (
                <Step3 data={data[stepIndex]} key={stepIndex} stepIndex={stepIndex} wizardStateData={wizardStateData} />
            )}
            {/* Bellow changed 4 -> 3 */}
            {stepIndex === 3 && (
                <Step4 data={data[stepIndex]} key={stepIndex} stepIndex={stepIndex} wizardStateData={wizardStateData} updateWizardStateStep={updateWizardStateStep} />
            )}
            {/* Bellow changed 2 -> 4  */}
            {stepIndex === 4 && (
                <div style={styles.plans}>
                    <Step5 data={data[stepIndex]} key={stepIndex} stepIndex={stepIndex} wizardStateData={wizardStateData} />
                </div>
            )}
            {stepIndex === 5 && (
                <Step6 data={data[stepIndex]} key={stepIndex} stepIndex={stepIndex} wizardStateData={wizardStateData} updateWizardStateStep={updateWizardStateStep} />
            )}

            {/* Side Lottie */}
            {(isDesktop && (
                <div style={styles.lottiePosition} className="position-absolute ">
                    {/* <Lottie style={{ width: lottieWidth }} animationData={idleRobot} /> */}
                    <Robot width={lottieWidth} state={data[stepIndex].robot} />
                </div>
            ))}
        </div>
    );
}

export default WizardSteps;
