import { buildActionType } from '../actions/utils/buildActionType';
import { ActionStatus } from '../core/ActionStatus';
import { StateStatus } from '../core/StateStatus';

const initialState = {
    data: {
        currentStepIndex: 1,
        highestReachedStepIndex: 0,
        steps: [
            {
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                allFieldsEntered: false,
                successfulValidation: true,
                newLeadSent: false,
            },
            {
                industryIndex: -1,
                subIndustryIndex: -1,
                subIndustryChecked: [],
                industryName: '',
                validated: false,
                allFieldsEntered: false,
                successfulValidation: true,
            },
            {
                modelIndex: 0,
                allFieldsEntered: true,
                successfulValidation: true,
            },
            {
                selectedDropdownIndex: 0,
                // selectedIndex: -1,
                // freeDomainValue: '',
                yourDomainValue: '',
                yourDomainPostfixIndex: 0,
                postfixValue: '',
                existingDomain: '',
                domainAvailable: true,
                allFieldsEntered: false,
                successfulValidation: true,
            },
            {
                planIndex: 1,
                allFieldsEntered: true,
                successfulValidation: true,
            },
            {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                address: '',
                zip: '',
                city: '',
                company: '',
                edb: '',
                selectedPaymentIndex: 1,
                selectedEntityIndex: 0,
                allFieldsEntered: false,
                successfulValidation: true,
            }
        ],
    },
};
const wizardState = actionType => (state = initialState, action) => {
    switch (action.type) {
        case buildActionType(actionType, ActionStatus.START):
            return {
                ...state,
                status: StateStatus.START,
                entity: actionType.entity,
            };


        default:
            return state;
    }
};

export default wizardState;
