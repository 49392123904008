import React from 'react';

function ErrorMessage({ errorMessage }) {
    return (
        <div style={{ color: '#dc3545', width: '100%', marginTop: '0.25rem', fontSize: '80%', fontWeight: 400, position: 'absolute', bottom: -20 }}>
            {errorMessage}
        </div>
    )
}

export default ErrorMessage;