/* eslint-disable */
import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import PhoneStepper from './PhoneStepper';
import { IsPhone } from '../../../../services/CustomMediaQueries';

export default function WizardStepper({ stepIndex, steps, setCurrentStepIndex, wizardStateData }) {
    const handleStepClick = (step) => {
        if (wizardStateData.highestReachedStepIndex >= step)
            setCurrentStepIndex(step);
    };

    return (
        <div >
            {IsPhone() && (
                <PhoneStepper steps={steps} activeStep={stepIndex} handleStepClick={handleStepClick} />
            ) || (
                    <Stepper activeStep={stepIndex} alternativeLabel >
                        {steps.map((label, index) => (
                            <Step key={label} onClick={() => { handleStepClick(index) }} >
                                <StepLabel >{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                )}
        </div>
    );
}

