import { palette } from './../../theme/theme-constants';

export const inputs = {
    MuiFormLabel: {
        root: {
            '&.Mui-focused': {
                color: palette.secondary.main,
            },
        },
    },
    MuiInput: {
        underline: {
            '&.Mui-focused:after': {
                borderColor: palette.secondary.main,
            },
            '&::after': {
                borderColor: palette.secondary.light,
            },
            '&:hover:not(.Mui-disabled):before': {
                borderColor: palette.secondary.light,
            },
        },
    },
    MuiSelect: {
        root: {
            textAlign: 'left',
        },
        select: {
            '&:focus': {
                backgroundColor: palette.background.input,
            },
        },
    },
    MuiOutlinedInput: {
        inputMarginDense: {
            paddingTop: '16.5px',
        },
    },
    MuiInputLabel: {
        outlined: {
            '&:not(.Mui-focused):not(.MuiFormLabel-filled)': {
                marginTop: '4px',
            },
        },
    },
    MuiFilledInput: {
        root: {
            backgroundColor: palette.background.input,
            '&.Mui-focused': {
                backgroundColor: palette.background.input,
            },
        },
    },
};
