import { Action } from '../core/Action';

export const GET_WIZARD = {
    entity: 'GET_WIZARD',
    action: Action.HANDLE,
};

export const GET_CATEGORIES = {
    entity: 'GET_CATEGORIES',
    action: Action.HANDLE,
};

export const GET_MODELS = {
    entity: 'GET_MODELS',
    action: Action.HANDLE,
};

export const WIZARD_STATE = {
    entity: 'WIZARD_STATE',
    action: Action.HANDLE,
};

export const PROPERTIES = {
    entity: 'PROPERTIES',
    action: Action.HANDLE,
};

export const GET_PRICING_TABLE = {
    entity: 'GET_PRICING_TABLE',
    action: Action.HANDLE,
};

export const GET_INSIGHTS = {
    entity: 'GET_INSIGHTS',
    action: Action.HANDLE,
};


