import { createMuiTheme } from '@material-ui/core/styles';
import { buttons } from './buttons';
import { gw_bold, gw_light, gw_medium, gw_regular } from './fonts';
import { inputs } from './inputs';
import { lists } from './lists';
import { tabs } from './tabs';
import { uploadUsersOverrides } from './uploadUsers';

export default createMuiTheme({
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [gw_regular, gw_light, gw_medium, gw_bold],
            },
        },

        ...buttons,
        ...uploadUsersOverrides,
        ...tabs,
        ...inputs,
        ...lists,
    },
});
