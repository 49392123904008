/* eslint-disable */
import React, { useMemo, useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import _includes from 'lodash/includes';
import _isEqual from 'lodash/isEqual';
import _capitalize from 'lodash/capitalize';
import { makeValidate, TextField } from 'mui-rff';
import * as Yup from 'yup';
import { Box, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import PhoneInput from '../../../../form/PhoneInput.js';

import { useTranslation } from 'react-i18next';
import { useCommonValidation } from '../../../../form/validation.js';
import { getTime } from './bookMeetingFunctions';
import { EmailInput } from '../../../../form/EmailInput';

function trimDashesFromString(phoneNumber) {
  const phoneNumbers = phoneNumber.split('-');

  return `${phoneNumbers[0]}${phoneNumbers[1]}${phoneNumbers[2]}`;
}

function BookMeetingForm({ isTimeSelect, setIsTimeSelect, timeValues }) {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const [timeSelected, setTimeSelected] = useState('');
  const isBusiness = _includes(pathname, 'book-meeting');

  const onSubmit = async values => {
    const { first_name, last_name, email, phonePrefix, phoneNumber } = values;

    const phoneNumberWithoutDashes = trimDashesFromString(phoneNumber);
    const mobile_phone = `${phonePrefix}${phoneNumberWithoutDashes}`;

    let obj = {
      eventId: timeValues.availableTimeslot.id,
      client_first_name: first_name,
      client_last_name: last_name,
      client_phone: mobile_phone,
      attendees: [{ email: email }, { email: 'dejan.milosavleski@altius.mk' }, { email: 'ivana.bibanovska@altius.mk' }, { email: 'angela.nikolovska@altius.mk' },{ email: 'boban.milosavleski@altius.mk' },{ email: 'lence.milosavleska@altius.mk' }],
      visibility: 'private'
    };

    console.log(obj, 'obj');
    try {
      setServerError(null);
      setIsLoading(true);

     axios({
        method: 'POST',
        url: `https://stagebook.vendor.com.mk/update-event`,
        headers :{
          "Content-Type": "application/json",
          // "Accept": "application/json"
        //  "Access-Control-Allow-Origin" : "*",
      },
        data: JSON.stringify(obj)
      }).then(res => {
        console.log("res", res.data);
        if(res.data){
          push('/successful-book-meeting');
        }
      })
     
      setIsLoading(false);
    } catch (error) {
    }
  };

  const { alphanumeric, email, strongPassword } = useCommonValidation();

  const fields = [
    {
      name: 'first_name',
      autoComplete: 'given-name',
      component: TextField,
      placeholder: t('register.name')
    },
    {
      name: 'last_name',
      autoComplete: 'family-name',
      component: TextField,
      placeholder: t('register.lastName')
    },
    {
      name: 'email',
      autoComplete: 'email',
      component: EmailInput,
      placeholder: t('register.email')
    },
    {
      name: 'phone',
      autoComplete: 'tel-national',
      component: PhoneInput,
      placeholder: t('register.phone'),
      InputProps: { inputMode: 'numeric' }
      // fieldProps: {
      //   parse: phoneParser,
      // }
    }
  ];
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const schema = useMemo(() => {
    return Yup.object().shape({
      first_name: alphanumeric.required(t('register.validation.name')),
      last_name: alphanumeric.required(t('register.validation.lastName')),
      email: email.required(t('register.validation.email')),
      phoneNumber: Yup.string().required(t('register.validation.phone'))
    });
  }, []);
  const validate = makeValidate(schema);

  const formInitialValues = {
    phonePrefix: '389'
  };

  useEffect(() => {
    if (timeValues) {
      const startTime = timeValues.startTime.toString();
      const time = getTime(startTime);
      setTimeSelected(time);
    }
  }, [timeValues]);

  return (
    <div
      style={{
        visibility: isTimeSelect ? 'visible' : 'collapse',
        opacity: isTimeSelect ? '1' : '0',
        position: 'absolute',
        top: '100px',
        left: '50%',
        transform: `translate(-50%,0px)`,
        minWidth: '360px',
        transition: 'all 1.2s'
      }}
    >
      <div className="" style={{ minHeight: 500 }}>
        <div className="container pt-3">
          <Form
            validate={validate}
            initialValuesEqual={_isEqual}
            initialValues={formInitialValues}
            onSubmit={onSubmit}
            render={({ handleSubmit, values }) => {
              return (
                <>
                  <div className="row pb-3" style={{ borderRadius: 16 }}>
                    <div
                      className="mx-auto registration-square-wrapper col-12  mx-auto px-md-4"
                      style={{ paddingRight: '4px' }}
                    >
                      <div className="register-cta col-12 text-center mt-md-5 mt-0">
                        <img
                          src="/semicircular-up-arrow.svg"
                          className="cta-arrow"
                        />
                        <h3 className="c-indigo pt-2 text-left">
                          Закажи состанок во {timeSelected}
                        </h3>
                      </div>
                      <form className="col-12" onSubmit={handleSubmit}>
                        <Box
                          display="flex"
                          style={{ flexFlow: 'column', marginTop: '20px' }}
                        >
                          {fields.map(field => {
                            return (
                              <field.component
                                key={field.name}
                                name={field.name}
                                className={`${classes.input} registration-input`}
                                placeholder={field.placeholder}
                                InputProps={{
                                  autoComplete: field.autoComplete,
                                  ...field.InputProps
                                }}
                                fieldProps={field.fieldProps}
                              />
                            );
                          })}

                          {serverError && (
                            <Alert severity="error" color="error">
                              <p>{serverError}</p>
                            </Alert>
                          )}
                          <div className="w-100 text-center">
                            <button
                              // id="book-meeting-button"
                              onClick={handleSubmit}
                              type="submit"
                              disabled={isLoading}
                              className="pink-button fs-18 mt-2 text-center w-100"
                            >
                              Закажи состанок
                            </button>
                            <button
                              onClick={() => setIsTimeSelect(false)}
                              type="button"
                              className="light-gray-button fs-14 mt-3 text-center w-50"
                            >
                              Избери друг термин
                            </button>
                          </div>
                        </Box>
                      </form>
                    </div>
                  </div>
                </>
              );
            }}
          />
          <div className="pb-5 col-md-8 mx-auto" />
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  input: {
    marginTop: '0.8rem !important',
    minHeight: '50px',
    fontWeight: '100',
    fontSize: '17px !important'
  },
  checkbox: {
    marginTop: '1rem !important',
    minHeight: '30px',
    fontWeight: '100'
  },
  termsAndConditions: {
    marginTop: '1rem !important',
    fontSize: '6px !important',
    fontWeight: '100 !important'
  }
});

export default BookMeetingForm;
