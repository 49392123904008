import * as React from 'react';

export const VariousPropContext = React.createContext({});





export default function WithVariousPropContext ({
  children,
}) {
   const [ plan, setPlan ] = React.useState(0);
   const [ subscription_period, setSubscription_period ] = React.useState("");

  
  return (
    <VariousPropContext.Provider
      value={{ plan, setPlan, subscription_period, setSubscription_period }}
    >
      {children}
    </VariousPropContext.Provider>
  );
}
