import { Wave1 } from '../../components/landing/animated_text';

function Section6(props) {
  return (
    <div className="container py-90 mb-5">
      <div className="row">
        <div className="col-md-12 mb-40">
          <h1 className="ff-literal fs-36 text-center c-dark-blue lh-45">
            {props.title}
          </h1>
        </div>
        <div className="col-md-12 text-center">
          <a className="pink-button" id="footer-register-button" href="/register-business">
            {props.button_label}
          </a>
        </div>
      </div>
    </div>
  );
}

export default Section6;
