import React from 'react';
import { Typography } from '@material-ui/core';

export const BasicLabel = ({ children, style, labelSize }) => {
  let styleObject = style || {};
  if (labelSize) styleObject = { ...styleObject, fontSize: `${labelSize}rem` };

  return (
    <Typography style={styleObject}>
      {children}
    </Typography>
  );
};
