/* eslint-disable */
import React, { useEffect, useState } from "react";

import heroBanner from "./../../assets/images/vendor-hero-2.png";
import robotAssistant from "./../../assets/images/robot.svg";
import robotWizardFlow from "./../../assets/images/robot-wizard.svg";
import { Helmet } from "react-helmet";

import Header from "../components/landing/Header";
import Logos from "../components/landing/logos";
import Footer from "../components/landing/footer";
import Section6 from "../components/landing/section_6";

import Lottie from "lottie-react";
import thumbsUpRobot from "../../assets/animations/wave.json";

import axios from "axios";
import { stateIsLoaded } from "../../services/stateHelpers";
import { useDispatch, useSelector } from "react-redux";
import { data } from "jquery";
import { useHistory, useParams } from "react-router-dom";
import { GET_SINGLE_INSIGHT_API } from "../../services/api";
import { browserHistory } from "react-router";
import * as qs from "query-string";
import { useTranslation } from "react-i18next";
import { Wave1, Wave2, Wave3 } from "../components/landing/animated_text";
import integrationsData from "../../assets/api/mk/integrations.json";
import Marquee from "react-fast-marquee";
import parse from "html-react-parser";
function Why(props) {
  const { push } = useHistory();
  let { params } = useParams();

  const { t } = useTranslation();
  const [landingData, setLandingData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [insightData, setInsightData] = useState();
  const [isInsightShown, setIsInsightShown] = useState(false);
  const [shownInsightId, setShownInsightId] = useState(0);

  // const insightClicked = (id) => {
  //   setIsInsightShown(true);
  //   setShownInsightId(id);
  //   fetchInsight(id);
  //   push('/insights?insight=' + id)
  // }

  const fetchInsight = async (id) => {
    setIsLoading(true);
    const result = await axios(GET_SINGLE_INSIGHT_API + "/" + id);
    setInsightData(result.data);
    setIsLoading(false);
  };

  const handleBackClick = () => {
    console.log("BACK " + isInsightShown);
    if (isInsightShown) setIsInsightShown(false);
  };

  const { listen } = useHistory();
  useEffect(() => {
    const unlisten = listen((location) => {
      handleBackClick();
    });

    // remember, hooks that add listeners
    // should have cleanup to remove them
    return unlisten;
  }, []);

  const dispatch = useDispatch();

  return (
    <div>
      <Helmet titleTemplate="Vendor - %s">
        <meta charSet="utf-8" />
        <title>Што е Vendor?</title>
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta name="author" content="Vendor" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />

        <meta property="og:title" content="Vendor - Rethink selling" />
        <meta
          property="og:description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta
          property="og:image"
          content="https://vendor.com.mk/facebook-thumbnail.jpg"
        />
        <meta property="og:url" content="https://vendor.com.mk/" />

        <meta name="twitter:title" content="Vendor - Rethink selling" />
        <meta
          name="twitter:description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta
          name="twitter:image"
          content="https://vendor.com.mk/twitter-thumbnail.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div>
        <Header></Header>
        <div
          className="container-fluid d-flex align-items-center mx-0 px-0"
          id="why-vendor-wrapper"
          style={{
            backgroundImage: `url("/vendor-bg.jpg"`,
            backgroundSize: "cover",
          }}
        >
          <div class="container px-0 px-md-2">
            <div className="row mx-0 my-4 py-4 align-items-center">
              <div className="col-md-10  mx-auto text-center">
                {/* <h3 className=" fs-30  text-uppercase lh-20 letter-spacing-2 pb-2 c-indigo text-left text-md-center">
                  Што е Vendor?
                </h3> */}
                <h1 className="fs-46 lh-30 pb-0 c-white text-center vendor-main-desc ff-literal">
                  Добредојде во светот на
                </h1>
                <img
                  src="/logo-all-white-word-only.svg"
                  width="220px"
                  className="mx-auto"
                ></img>
                <div className="col-md-5 mx-auto text-center my-3">
                  {/* <img src="/vendor-screens-2.png" className="img-fluid" /> */}
                  <video
                    width="100%"
                    height="auto"
                    controls
                    poster="/video-cover.jpg"
                  >
                    <source
                      src="/vendor-rethink-selling.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <h3 className="fs-20  text-uppercase lh-20 letter-spacing-2 pb-2 c-white text-center">
                  All-in-One платформа за <nobr>е-трговија</nobr>
                </h3>
                <div className="my-5">
                  <a
                    id="header-register-button"
                    class="header-button"
                    href="#about"
                  >
                    Дознај повеќе
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container my-md-4 py-md-4 mt-0 pt-0 px-md-2 px-0">
          <div
            className="row mx-0 my-4 py-4 align-items-center"
            id="multipass-wrapper"
          >
            <div className="col-md-5 ">
              <h2 className="ff-literal fs-36 lh-42 mb-2 c-indigo">
                За кого е <span className="c-magenta">Vendor?</span>
              </h2>
              <p className="mb-3 fs-18">
                Со специјализиран дизајн за преку 137 категории на бизниси,
                Vendor Е-продавниците се дизајнирани да овозможат максимално
                корисничко искуство на крајните купувачи.
                <br />
              </p>
            </div>
            <div className="col-md-7 text-center">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div
                    className="profiles-card my-2 gradient-1"
                    // style={{ backgroundImage: `url("/glass.svg")` }}
                  >
                    <h2 className="ff-literal fs-20 lh-42 mb-2 c-white text-uppercase">
                      Компании
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div
                    className="profiles-card my-2 gradient-2"
                    // style={{ backgroundImage: `url("/glass.svg")` }}
                  >
                    <h2 className="ff-literal fs-20 lh-42 mb-2 c-white text-uppercase">
                      Претприемачи
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div
                    className="profiles-card my-2 gradient-3"
                    // style={{ backgroundImage: `url("/glass.svg")` }}
                  >
                    <h2 className="ff-literal fs-20 lh-42 mb-2 c-white text-uppercase">
                      Дизајнери
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div
                    className="profiles-card my-2 gradient-4"
                    // style={{ backgroundImage: `url("/glass.svg")` }}
                  >
                    <h2 className="ff-literal fs-20 lh-42 mb-2 c-white text-uppercase">
                      Програмери
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div
                    className="profiles-card my-2 gradient-5"
                    // style={{ backgroundImage: `url("/glass.svg")` }}
                  >
                    <h2 className="ff-literal fs-20 lh-42 mb-2 c-white text-uppercase">
                      Маркетери
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div
                    className="profiles-card my-2 gradient-6"
                    // style={{ backgroundImage: `url("/glass.svg")` }}
                  >
                    <h2 className="ff-literal fs-20 lh-42 mb-2 c-white text-uppercase">
                      Агенции
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div
          className="container my-md-4 py-md-4 mt-0 pt-0 px-md-2 px-0"
          id="about"
        >
          <div
            className="row mx-0 my-4 py-4 align-items-center"
            id="multipass-wrapper"
          >
            <div className="col-md-6 text-center">
              <img src="/vendor-screens-2.png" className="img-fluid" />
            </div>
            <div className="col-md-6 ">
              <h2 className="ff-literal fs-36 lh-42 mb-0 c-indigo">
                Што е <span className="c-magenta">Vendor?</span>
              </h2>
              <h3 className="ff fs-20 lh-42 mb-2 c-indigo">
                <span className="c-indigo">All-in-One</span> платформа за
                <nobr>е-трговија</nobr>
              </h3>
              <p className="mb-3 fs-18">
                Со специјализиран дизајн за преку 137 категории на бизниси,
                Vendor е-продавниците се дизајнирани да овозможат максимално
                корисничко искуство на крајните купувачи.
                <br />
              </p>

              {/* <div className="styledList">
                 <div class="styledListItem mb-3">
                  <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                    <img
                      src="/circle.svg"
                      width="20px"
                      className="circle mr-1"
                    />
                    <h4 className="c-indigo fs-20">
                      {parse(t('about.multipass.registrationHeader'))}
                    </h4>
                  </div>
                  <div className="listParagraph">
                    <p>{parse(t('about.multipass.registrationContent'))}</p>
                  </div>
                </div>
                <div class="styledListItem mb-3">
                  <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                    <img
                      src="/circle.svg"
                      width="20px"
                      className="circle mr-1"
                    />
                    <h4 className="c-indigo fs-20">
                      {parse(t('about.multipass.controlHeader'))}
                    </h4>
                  </div>
                  <div className="listParagraph">
                    <p>{parse(t('about.multipass.controlContent'))}</p>
                  </div>
                </div>
                <div class="styledListItem mb-3">
                  <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                    <img
                      src="/circle.svg"
                      width="20px"
                      className="circle mr-1"
                    />
                    <h4 className="c-indigo fs-20">
                      {parse(t('about.multipass.usageHeader'))}
                    </h4>
                  </div>
                  <div className="listParagraph">
                    <p>{parse(t('about.multipass.usageContent'))}</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div
          className="container-fluid py-5 mx-0 px-0 gradient-3 mesh-gradient"
          style={{
            backgroundImage: `url("/vendor-bg.jpg"`,
            backgroundSize: "cover",
          }}
        >
          <div className="container px-md-2 px-0">
            <div
              className="row mx-0 my-4 py-4 align-items-center"
              id="multipass-wrapper"
            >
              <div className="col-md-6 text-center">
                <div className="my-2">
                  <img src="/business.png" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-6">
                <h3 className="mb-0 fs-18 about-section-tagline c-white">
                  причини зошто e Vendor за тебе
                </h3>
                <h1 className="fs-46 lh-30 pb-0 c-white vendor-main-desc ff-literal">
                  aко си Компанија
                </h1>

                <div className="styledList">
                  <div class="styledListItem mb-3">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="c-white fs-20">
                        Готови интеграции со сите регионални банки
                      </h4>
                    </div>
                    {/* <div className="listParagraph">
                    <p>{parse(t('about.vendorErp.point1c'))}</p>
                  </div> */}
                  </div>
                  <div class="styledListItem mb-3">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="c-white fs-20">
                        Синхронизација и извешати во реално време
                      </h4>
                    </div>
                    {/* <div className="listParagraph">
                    <p>{parse(t('about.vendorErp.point2c'))}</p>
                  </div> */}
                  </div>
                  <div className="styledListItem mb-3">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="c-white fs-20">
                        Поврзување со ERP, CRM и трети системи
                      </h4>
                    </div>
                    {/* <div className="listParagraph">
                    <p>{parse(t('about.vendorErp.point3c'))}</p>
                  </div> */}
                  </div>
                </div>
                <p className="mb-3 fs-16 c-white">
                  Ја трансформираме моќта на ТИ во моќта на НИЕ. Заедно, ќе го
                  поедноставиме раководењето на твојот онлајн бизнис и ќе ја
                  обезбедиме потребната стабилност за да напредува.
                </p>

                <p className="mb-3 fs-16 c-white">
                  Со Vendor, ја имаш можноста да освоиш нови пазари, без бариери
                  и да го лансираш твојот бизнис на секој дел од планетата.
                </p>
                <div className="my-2">
                  <a
                    className="pink-button"
                    id="about-register-button"
                    href="/register"
                  >
                    Регистрирај се
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid py-5 mx-0 px-0 ">
          <div className="container px-md-2 px-0">
            <div
              className="row mx-0 my-4 py-4 align-items-center"
              id="multipass-wrapper"
            >
              <div className="col-md-6">
                <h3 className="mb-0 fs-18 about-section-tagline c-darker-blue">
                  причини зошто e Vendor за тебе
                </h3>
                <h1 className="fs-46 lh-30 pb-0 c-magenta vendor-main-desc ff-literal">
                  ако си Претприемач
                </h1>

                <div className="styledList">
                  <div class="styledListItem mb-3">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="c-indigo fs-20">
                        Една претплата, без трошоци за хостинг и одржување
                      </h4>
                    </div>
                    {/* <div className="listParagraph">
                    <p>{parse(t('about.vendorErp.point1c'))}</p>
                  </div> */}
                  </div>
                  <div class="styledListItem mb-3">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="c-indigo fs-20">
                        Менаџирање на е-продавница без техничко предзнаење
                      </h4>
                    </div>
                    {/* <div className="listParagraph">
                    <p>{parse(t('about.vendorErp.point2c'))}</p>
                  </div> */}
                  </div>
                  <div className="styledListItem mb-3">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="c-indigo fs-20">
                        Поврзување на платежен процесор дури и за физички лица
                      </h4>
                    </div>
                    {/* <div className="listParagraph">
                    <p>{parse(t('about.vendorErp.point3c'))}</p>
                  </div> */}
                  </div>
                </div>

                <p className="mb-3 fs-16">
                  Го поддржуваме почетокот на секој бизнис и му овозможуваме да
                  се искачи на нови височини! Можеш успешно да управуваш и да
                  раководиш со твојата е-продавница без помош од надворешен тим
                  на програмери.
                </p>
                <p className="mb-3 fs-16">
                  За сите уникатни деловни потреби, Vendor тимот обезбедува
                  насоки, технички совети и бизнис предлози со цел да ти помогне
                  да започнеш и да напредуваш во оваа конкурентна индустрија.
                </p>
                <div className="my-2">
                  <a
                    className="pink-button"
                    id="about-register-button"
                    href="/register"
                  >
                    Регистрирај се
                  </a>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <div className="my-2">
                  <div className="my-2">
                    <img src="/startup.png" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid py-5 mx-0 px-0 gradient-6"
          style={{
            backgroundImage: `url("/vendor-bg.jpg"`,
            backgroundSize: "cover",
          }}
        >
          <div className="container px-md-2 px-0">
            <div
              className="row mx-0 my-4 py-4 align-items-center"
              id="multipass-wrapper"
            >
              <div className="col-md-6 text-center">
                <div className="my-2">
                  <img src="/designers.png" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-6">
                <h3 className="mb-0 fs-18 about-section-tagline c-white">
                  причини зошто e Vendor за тебе
                </h3>
                <h1 className="fs-46 lh-30 pb-0 c-white vendor-main-desc ff-literal">
                  ако си Дизајнер или Програмер
                </h1>

                <div className="styledList">
                  <div class="styledListItem mb-3">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="c-white fs-20">
                        Различни дизајни и варијанти на компоненти кои ти
                        овозможуваат целосна уникатност во изгледот
                      </h4>
                    </div>
                    {/* <div className="listParagraph">
                      <p>{parse(t('about.vendorErp.point1c'))}</p>
                    </div> */}
                  </div>
                  <div class="styledListItem mb-3">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="c-white fs-20">
                        Лесен и интуитивен дизајн конфиугуратор
                      </h4>
                    </div>
                    {/* <div className="listParagraph">
                      <p>{parse(t('about.vendorErp.point2c'))}</p>
                    </div> */}
                  </div>
                  <div className="styledListItem mb-3">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="c-white fs-20">
                        Готови API интеграции со трети сервиси
                      </h4>
                    </div>
                    {/* <div className="listParagraph">
                      <p>{parse(t('about.vendorErp.point3c'))}</p>
                    </div> */}
                  </div>
                  <div className="styledListItem mb-3">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="c-white fs-20">
                        Kubernetes Cloud инфраструктура што овозможува највисоки
                        перформанси и безбедност
                      </h4>
                    </div>
                    {/* <div className="listParagraph">
                      <p>{parse(t('about.vendorErp.point3c'))}</p>
                    </div> */}
                  </div>
                </div>

                <p className="mb-3 fs-16 c-lavander">
                  Високо интуитивен и лесен за користење, дури и за оние кои
                  немаат претходно познавање на веб дизајн или програмирање.
                </p>
                <p className="mb-3 fs-16 c-lavander">
                  Користејќи ја Vendor платформата, имаш можност да го
                  прилагодиш визуелниот изглед на секоја компонента,
                  овозможувајќи ти целосна контрола врз твојата е-продавница.
                </p>
                <div className="my-2">
                  <a
                    className="pink-button"
                    id="about-register-button"
                    href="/register"
                  >
                    Регистрирај се
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid py-5 mx-0 px-0 ">
          <div className="container px-md-2 px-0">
            <div
              className="row mx-0 my-4 py-4 align-items-center"
              id="multipass-wrapper"
            >
              <div className="col-md-6">
                <h3 className="mb-0 fs-18 about-section-tagline c-darker-blue">
                  причини зошто e Vendor за тебе
                </h3>
                <h1 className="fs-46 lh-30 pb-0 c-magenta vendor-main-desc ff-literal">
                  ако си Маркетер
                  <br />
                  или Агенција
                </h1>

                <div className="styledList">
                  <div class="styledListItem mb-3">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="c-indigo fs-20">
                        Комплетен модул за Affiliate маркетинг
                      </h4>
                    </div>
                    {/* <div className="listParagraph">
                      <p>{parse(t('about.vendorErp.point1c'))}</p>
                    </div> */}
                  </div>
                  <div class="styledListItem mb-3">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="c-indigo fs-20">
                        Напредни аналитики за продажби и купувачи
                      </h4>
                    </div>
                    {/* <div className="listParagraph">
                      <p>{parse(t('about.vendorErp.point2c'))}</p>
                    </div> */}
                  </div>
                  <div className="styledListItem mb-3">
                    <div className="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                      <img
                        src="/circle.svg"
                        width="20px"
                        className="circle mr-1"
                      />
                      <h4 className="c-indigo fs-20">
                        Vendor Reseller програма
                      </h4>
                    </div>
                    {/* <div className="listParagraph">
                      <p>{parse(t('about.vendorErp.point3c'))}</p>
                    </div> */}
                  </div>
                </div>

                <p className="mb-3 fs-16">
                  Креирај извештаи во реално време додека ги следиш сите
                  продажби и кориснички движења и адаптирај ја твојата продажба
                  стратегија.
                </p>
                <p className="mb-3 fs-16">
                  Vendor ти обезбедува детална аналитика и комплетен сет на
                  маркетинг алатки и тактики, купони, newsletter и affiliate
                  маркетинг програми, кои ќе ти овозможат успешно започнување и
                  раководење со твојот онлајн бизнис како и подобрување на
                  стратегиите за продажба.
                </p>
                <a
                  className="pink-button"
                  id="about-register-button"
                  href="/register"
                >
                  Регистрирај се
                </a>
              </div>
              <div className="col-md-6 text-center">
                <img src="/affiliate.png" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid py-5 mx-0 px-0"
          style={{
            backgroundImage: `url("/vendor-bg.jpg"`,
            backgroundSize: "cover",
          }}
        >
          <div className="container my-4">
            <div className="row mx-0 my-4 py-4 align-items-center">
              <div className="col-md-6">
                <div class="col-md-8 px-0">
                  <img src="/logo-white.svg" className="img-fluid" />
                </div>
                <p className="c-lavander mt-3 fs-18 ">
                  Користејќи најсофистицирани технологии Vendor овозможува
                  креирање на е-продавница за само неколку минути, што во целост
                  го олеснува процесот на дигитализирање на твоите продажби.
                </p>
                <a
                  className="pink-button"
                  id="about-register-button"
                  href="/register"
                >
                  {parse(t("about.vendorClub.register"))}
                </a>
              </div>
              <div className="col-md-6 text-center robot-wrap">
                <Lottie className="" animationData={thumbsUpRobot} />
              </div>
            </div>
          </div>
        </div>

        <div className="my-2">
          <Marquee speed={60} gradient={false}>
            {integrationsData?.posts?.map((item, index) => {
              return (
                <img
                  style={{
                    borderRadius: "10px",
                    // filter: "grayscale(100)",
                    // opacity: "50%",
                  }}
                  className="logo-marquee"
                  src={item.image}
                  alt={"img"}
                />
              );
            })}
          </Marquee>
        </div>

        <Footer></Footer>
      </div>
    </div>
  );
}

export default Why;
