import Swagger from 'swagger-client';

export default function swaggerClient(
  specUrl,
  swaggerOpts,
) {

  Swagger.http.withCredentials = true;
  return Swagger(specUrl, swaggerOpts)
    .catch((...args) => {
      console.error('Error happened while creating a swagger-client', args);
      return Promise.reject(args);
    })
    .then((client) => client);
}
