/* eslint-disable */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { stateIsLoaded } from '../../../../services/stateHelpers';
import ShopStyleCard from './step-4/ShopStyleCard'

function Step3({ data, stepIndex, wizardStateData }) {

    const [fix, setFix] = useState('');

    const modelsData = useSelector(state => state.models.data);

    const handleModelChange = (index) => {
        wizardStateData.steps[stepIndex].modelIndex = index;
        setFix(index);
    }


    const modelsState = useSelector(state => state.models);
    if (!stateIsLoaded(modelsState))
        return <div></div>

    return (
        <div className="row justify-content-between mt-4">
            {modelsData.map((item, index) => (
                item.categories?.includes(wizardStateData.steps[stepIndex].industryName) || true && (
                    <ShopStyleCard data={item} selected={wizardStateData.steps[stepIndex].modelIndex === index} paddingOnRightSide={index % 2 === 0} handleModelChange={handleModelChange} cardIndex={index} />
                )
            ))}
        </div>
    )
}

export default Step3;
