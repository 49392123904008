/* eslint-disable */

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import arrowLeftIcon from '../../../assets/icons/arrow-left.png';
import arrowRightIcon from '../../../assets/icons/arrow-right.png';
import { getModelsAction } from '../../../redux/actions/vendorActions';
import { GET_DOMAIN_CHECK, POST_NEW_LEAD } from '../../../services/api';
import ApiExecutor from '../../../services/Api/ApiExecutor';
import { validateEmail } from '../../../services/StringHelpers';

function NavigationButtons({ currentStepIndex, handleBackButton, handleNextButton, totalSteps, wizardStateData, updateWizardState }) {

    // const wizardStateData = useSelector(state => state.wizardState.data);

    const dispatch = useDispatch();

    let styles = {
        rightArrowDisabled: {
            background: '#EFA2BE',
            borderRadius: '25px',
            color: '#FFFFFF',
            borderWidth: 0,
            padding: '5px 25px',
            fontWeight: 500,
            fontSize: 17
        },
        rightArrowEnabled: {
            background: '#eb1e5f',
            borderRadius: '25px',
            color: '#FFFFFF',
            borderWidth: 0,
            padding: '5px 25px',
            fontWeight: 500,
            fontSize: 17
        },
        leftArrow: {
            background: 'transparent',
            // borderRadius: '25px',
            color: '#3dabe0',
            borderWidth: 0,
            fontWeight: 500,
            fontSize: 17
        },
    };


    const onNextClick = async () => {
        const stepData = wizardStateData.steps[currentStepIndex];
        var successfulValidation = true;

        if (currentStepIndex === 0)
            successfulValidation = validateStep1(stepData);
        else if (currentStepIndex === 1) {
            successfulValidation = validateStep2(stepData);
            if (successfulValidation) {
                let query = '?cat[]=';
                query += wizardStateData.steps[currentStepIndex].subIndustryChecked.map(i => i.id).join("&cat[]=");
                dispatch(getModelsAction(query))
            }
        }
        // else if (currentStepIndex === 2)
        //     validateStep1(stepData);
        else if (currentStepIndex === 3)
            successfulValidation = await validateStep4(stepData);
        // else if (currentStepIndex === 4)
        //     validateStep1(stepData);
        else if (currentStepIndex === 5)
            successfulValidation = validateStep6(stepData);

        wizardStateData.steps[currentStepIndex].successfulValidation = successfulValidation;
        const stepIndex = successfulValidation ? currentStepIndex + 1 : currentStepIndex;
        // Only in step 3, becuase for the next step models have to be refetched.
        wizardStateData.highestReachedStepIndex = currentStepIndex;
        updateWizardState(stepIndex);
    }

    const validateStep1 = (stepData) => {
        var successfulValidation = true;

        if (stepData.firstName.length == 0)
            successfulValidation = false;
        if (stepData.lastName.length == 0)
            successfulValidation = false;
        if (stepData.phone.length < 11)
            successfulValidation = false;
        if (!validateEmail(stepData.email))
            successfulValidation = false;

        if (successfulValidation && !stepData.newLeadSent) {
            sendNewLead(stepData);
        }

        return successfulValidation;
    }

    const sendNewLead = (stepData) => {
        stepData.newLeadSent = true;

        const newLeadData = {
            FirstName: stepData.firstName,
            LastName: stepData.lastName,
            PhoneNumber: stepData.phone,
            Email: stepData.email
        }
        ApiExecutor('post', POST_NEW_LEAD, newLeadData);
    }

    const validateStep2 = (stepData) => {
        var successfulValidation = true;

        if (stepData.industryIndex == -1)
            successfulValidation = false;
        if (stepData.subIndustryChecked.length == 0)
            successfulValidation = false;

        return successfulValidation;
    }

    const validateStep4 = async (stepData) => {
        var successfulValidation = true;

        if (stepData.selectedDropdownIndex == -1)
            successfulValidation = false;
        else if (stepData.selectedDropdownIndex == 1 && stepData.existingDomain.length == 0)
            successfulValidation = false;
        else if (stepData.selectedDropdownIndex == 0 && stepData.yourDomainValue.length == 0)
            successfulValidation = false;
        else if (stepData.selectedDropdownIndex == 0) {
            const url = GET_DOMAIN_CHECK + stepData.yourDomainValue + stepData.postfixValue;
            let response = await ApiExecutor('get', url);
            // console.log("T2 " + JSON.stringify(response, null, '\t'))
            successfulValidation = (response.data.Available === 'true');    // Becuase it returns string
        }

        return successfulValidation;
    }

    const validateStep6 = (stepData) => {
        var successfulValidation = true;

        if (stepData.selectedIndex == 1) {
            if (stepData.firstName.length == 0)
                successfulValidation = false;
            if (stepData.mailValue.length == 0)
                successfulValidation = false;
            if (stepData.individual.length == 0)
                successfulValidation = false;
            if (stepData.legalEntity.length == 0)
                successfulValidation = false;
            if (stepData.legalSubject.length == 0)
                successfulValidation = false;
            if (stepData.edbValue.edb.length != 13)
                successfulValidation = false;
        }

        return successfulValidation;
    }

    return (
        <>
            {currentStepIndex >= 0 && currentStepIndex < totalSteps && (
                <div className="d-flex mb-4 pb-4 nav-buttons-wrapper" style={{ marginTop: 30 }}>
                    {/* Deleted -> Dolu: display-inline-flex Gore:align-items-center */}
                    <div className="w-100">
                        <div className="float-left">
                            <button style={styles.leftArrow} onClick={handleBackButton} type="button" >
                                <img alt={'img'} width={'18px'} src={arrowLeftIcon} className="mr-2" />
                                    Претходно
                                </button>
                        </div>
                        {currentStepIndex < totalSteps - 1 && (
                            <div className="float-right">
                                <button style={wizardStateData.steps[currentStepIndex].allFieldsEntered ? styles.rightArrowEnabled : styles.rightArrowEnabled} onClick={onNextClick} >
                                    Следно
                                    <img alt={'img'} width={'18px'} src={arrowRightIcon} className="ml-2 align-self-center" />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default NavigationButtons;
