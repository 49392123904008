/* eslint-disable */
import React from 'react';
import PricingTable from './PricingTable';
import arrowRightIcon from '../../../assets/icons/arrow-right.png';
import Lottie from "lottie-react";
import idleRobot from "../../../assets/lottie/idle.json";
import happyRobot from "../../../assets/lottie/thumbs-up.json";
import { IsPhone } from '../../../services/CustomMediaQueries';

import TriangleIcon from '../../../assets/icons/wizard-triangle.svg'

function WizardIntroAndOutro({ wizardData, stepIndex, submitIsSuccessful = false, handleNextButton, handleBackButton }) {

    let styles = {
        image: {
            height: '200px',
        },
        textBox: {
            background: '#FFFFFF',
            padding: '25px',
            borderRadius: '25px',
            border: '2px solid #C9EAF9',
            color: '#343287',
            fontWeight: 900,
            fontSize: '20px',
            // textAlign: 'center',
            position: 'relative',
        },
        rightArrow: {
            background: '#EB1E5F',
            borderRadius: '25px',
            color: '#FFFFFF',
            borderWidth: 0,
            padding: '10px 25px',
            fontWeight: 500,
            fontSize: 20 // Old: 18
        },
        trianglePhone: {
            position: 'absolute',
            width: 32,
            left: "calc(50% - 14px)",
            top: -32 * 2 - 1,
            transform: 'rotate(90deg)',
        },
        triangle: {
            position: 'absolute',
            width: 32,
            left: -32 + 2,
            top: 30
        },
        triangleOutro: {
            position: 'absolute',
            width: 32,
            left: -32 + 2,
            top: 20
        },
        rectangle1: {
            width: 70,
            height: 150,
            backgroundColor: '#6BC4F0',
            borderRadius: 15,
            position: 'absolute',
            right: 100,
            top: -35,
            transform: 'rotate(70deg)',
        },
        rectangle2: {
            width: 70,
            height: 150,
            backgroundColor: '#342F89',
            borderRadius: 15,
            position: 'absolute',
            left: 50,
            bottom: -15,
            transform: 'rotate(20deg)',
        },
    }

    const isPhone = IsPhone();

    return (
        <div className={"mt-5 pl-5 pr-5"}>
            {/* Intro */}
            {stepIndex === -1 && (
                <div className="justify-content-center" >
                    <div className="row align-items-center pl-5 pr-5">
                        <div className="col-sm-3">
                            <Lottie style={styles.image} animationData={idleRobot} />

                        </div>
                        <div className="col-sm-9" style={{ marginTop: isPhone ? 20 : 0 }}>
                            {/* Background rectangles */}
                            <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                                <div style={styles.rectangle1} />
                                <div style={styles.rectangle2} />
                            </div>
                            <div style={styles.textBox}>

                                <p >{wizardData.intro.title}</p>
                                <p className="fs-17 c-gray fw-300">{wizardData.intro.text}</p>

                                {/* Triangle */}

                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                        <button style={styles.rightArrow} onClick={handleNextButton}>
                            {wizardData.intro.next_button}
                            <img alt={'img'} width={'18px'} src={arrowRightIcon} className="ml-2 align-self-center" />
                        </button>

                    </div>
                    <div className='d-flex justify-content-center'>

            </div>
                </div>
            )}

            {/* Outro */}
            {stepIndex !== -1 && (
                <div className="justify-content-center" >
                    <div className="row align-items-center pl-5 pr-5">
                        <div className="col-sm-3">
                            <Lottie style={styles.image} animationData={happyRobot} />

                        </div>
                        <div className="col-sm-9" style={{ marginTop: isPhone ? 20 : 0 }}>
                            {/* Background rectangles */}
                            <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                                <div style={styles.rectangle1} />
                                <div style={styles.rectangle2} />
                            </div>
                            <div style={styles.textBox}>

                                <p >{wizardData.outro.title}</p>
                                <p className="fs-17 c-gray fw-300 pt-2">{wizardData.outro.text}</p>

                                {/* Triangle */}
                                <div style={isPhone ? styles.trianglePhone : styles.triangleOutro}>
                                    <img src={TriangleIcon} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                        {/* <button style={styles.rightArrow} onClick={handleNextButton}>
                            {wizardData.outro.next_button}
                            <img alt={'img'} width={'18px'} src={arrowRightIcon} className="ml-2 align-self-center" />
                        </button> */}

                    </div>
                </div>

                // <div className="justify-content-center">
                //     <div className="row align-items-center pl-5 pr-5">
                //         <div className="col-sm-3">
                //             <Lottie style={styles.image} animationData={happyRobot} />
                //         </div>
                //         <div className="col-sm-9" style={styles.textBox}>
                //             <p >{wizardData.outro.title}</p>
                //             <p className="fs-17 c-gray fw-300">{wizardData.outro.text}</p>
                //         </div>
                //     </div>
                //     <div className="d-flex mt-5" style={{ justifyContent: isPhone ? 'center' : 'flex-end' }}>
                //         <button style={styles.rightArrow} onClick={handleBackButton}>
                //             {wizardData.outro.complete_button.label}
                //             <img alt={'img'} width={'18px'} src={arrowRightIcon} className="ml-2 align-self-center" />
                //         </button>

                //     </div>
                // </div>
            )}

        </div>
    )
}

export default WizardIntroAndOutro;
